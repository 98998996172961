<!--
 * @Author: 张凤
 * @Date: 2021-10-19 
 * @Description: 详情信息中报表图表
-->
<template>
  <div class="report-charts">
    <div class="report-name">{{ chartsData.title }}</div>
    <div class="chart-box">
      <chartsComponent
        v-if="
          showChartInfos.dashboardtype !== 'tableDetail' &&
          showChartInfos.dashboardtype !== 'report_summary'
        "
        v-loading="chartsLoading"
        :dashboardtype="showChartInfos.dashboardtype"
        :groupOneArr="showChartInfos.groupOneArr"
        :valueDataArr="showChartInfos.valueDataArr"
        :dataArr="showChartInfos.dataArr"
        :seriesArr="showChartInfos.seriesArr"
        :dimensionsArr="showChartInfos.dimensionsArr"
        :sourceArr="showChartInfos.sourceArr"
        :listChartData="showChartInfos.listChartData"
        :min="showChartInfos.min"
        :max="showChartInfos.max"
        :mid1="showChartInfos.mid1"
        :mid2="showChartInfos.mid2"
        :isshowpercent="showChartInfos.isshowpercent"
        :isshowvalue="showChartInfos.isshowvalue"
        :mapGroupOneFieldLabelName="showChartInfos.mapGroupOneFieldLabelName"
        :mapGroupOneField="showChartInfos.mapGroupOneField"
        :mapSummaryFieldLabelName="showChartInfos.mapSummaryFieldLabelName"
        :mapSummaryWay="showChartInfos.mapSummaryWay"
        :objectLable="showChartInfos.objectLable"
        :reportName="showChartInfos.reportName"
        :unit="showChartInfos.unit"
        :showData="showData"
        @linkagegetData="seeReport"
      />
      <!-- 表格图表：分为两种，不带分组的tableDetail和带有分组的report         v-loading="realLoading"
        -->
      <form-chart
        v-if="
          showChartInfos.dashboardtype === 'tableDetail' ||
          showChartInfos.dashboardtype === 'report_summary'
        "
        v-loading="chartsLoading"
        :dashboardtype="showChartInfos.dashboardtype"
        :tableData="showData.tableData"
        :colData="showData.colData"
        :mapGroupOneField="showData.mapGroupOneField"
        :mapGroupTwoField="showData.mapGroupTwoField"
        :sourceType="showData.sourceType"
      />
      <div v-if="!showChartInfos && !showData" style="text-align: center">
        <svg
          class="icon"
          aria-hidden="true"
          style="width: 200px; height: 200px"
        >
          <use href="#icon-noDateAvailable"></use>
        </svg>
      </div>
    </div>
    <div class="report-tip">
      <div class="see-report" @click="seeReport">
        <!-- 查看报表 -->
        {{ $t("front-detail-module-v1-view-report") }}
      </div>
      <div class="effect-btn">
        <div class="effect-time">
          {{ $t("front-detail-module-v1-by") }}
          <!-- 截至 -->
          {{ this.showData.refreshDate }}
        </div>
        <div v-if="chartsData.isshow === 'true'">
          <i
            ref="loadimgbox"
            class="el-icon-refresh-right refresh-class"
            @click="fnrefresh"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartsComponent from "@/components/Chart/chartsComponent.vue"; // 图表
import FormChart from "@/views/dashboardObject/dashboardIndex/components/FormChart"; // 表格图表
import * as ReportObjectApi from "./api"; // 请求api

export default {
  components: {
    chartsComponent,
    FormChart,
  },
  props: {
    // 报表数据：id,是否显示刷新按钮等
    chartsData: {
      type: Array,
      default:()=> [],
    },
    // 记录id
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isRefresh: false, //是否刷新
      chartsLoading: true, //图表loading
      // 图表数据
      showData: {
        name: "", // 图表名称
        options: {}, // 图表options
        chartInfo: {}, // 图表基本信息
        reportInfo: {}, // 图表所选择的报表信息
        objectInfo: {}, // 图表所选择的对象信息
        viewInfo: {}, // 图表所选择的视图信息
        mapGroupOneField: {}, // 一级分组字段信息
        mapGroupTwoField: {}, // 二级分组字段信息
        mapSummaryField: {}, //=======
        statisticsValue: "", // number图表类型的统计值
        colorValue: "", // number图表类型具体的色值
        tableData: [], // 表格图表类型的表格行数据
        colData: [], // 表格图表类型的表格列数据
        sourceType: "", // 图表所使用的数据源：报表report、列表视图listView
        refreshDate: "",
      },
      chartTimer: "", //图表刷新按钮的间隔时间
      // 图表展示所需要的信息数据
      showChartInfos: {
        dashboardtype: "", // 图表类型
        listChartData: [], // 同环比双轴图返回数据
        groupOneArr: [], // 一级分组数据
        valueDataArr: [], // 统计数据
        dataArr: [], // data数据
        seriesArr: [], // series数据
        dimensionsArr: [], // dimensions数据
        sourceArr: [], // source数据
        min: "", // 指针图的最小值
        max: "", // 指针图的最大值
        mid1: "", // 指针图的断点1值
        mid2: "", // 指针图的断点2值
        isshowpercent: "false", // 是否显示百分比
        isshowvalue: "false", // 是否显示数值
        mapGroupOneFieldLabelName: "", // 一级分组的字段label
        mapSummaryFieldLabelName: "", // 统计字段的label
        mapSummaryWay: "", // 统计类型
        objectLable: "", // 列表视图数据源对象名称
        reportName: "", // 报表数据源报表名称
        unit: "", // 图表选择的单位
      },
    };
  },
  methods: {
    // 获取报表中图表的接口
    async getChartDataByReportForObjectDetail(value) {
      let obj = {
        id: this.chartsData.reportid, //报表id
        chartobjectdetailid: this.chartsData.id,
        isRefreshCache: this.isRefresh ? "true" : "false", //是否刷新缓存
        objectdetailrecordid: this.id, //是否设置了筛选依据，如果设置筛选依据传记录ID，否则传空this.chartsData.screening!=='无'? this.id:''
      };
      let data = await ReportObjectApi.getChartDataByReportForObjectDetail(
        obj
      ).catch(() => {
        this.chartsLoading = false;
        if (value === "refresh") {
          this.$refs.loadimgbox.classList.remove("loadimg");
          this.isRefresh = false; //刷新按钮默认false;
        }
      });
      if (data) {
        this.chartsLoading = false;
        let chartInfo = data.data.reportInfo; // 图表信息
        let datas = data.data;
        // 13种图表展示所需要的数据
        this.showChartInfos = {
          dashboardtype: chartInfo.dashboardtype,
          groupOneArr: datas.groupOneArr,
          listChartData: datas.listChartData,
          valueDataArr: datas.valueDataArr,
          dataArr: datas.data,
          seriesArr: datas.series,
          dimensionsArr: datas.dimensions,
          sourceArr: datas.source,
          min: chartInfo.min,
          max: chartInfo.max,
          mid1: chartInfo.mid1,
          mid2: chartInfo.mid2,
          isshowpercent: chartInfo.isshowpercent,
          isshowvalue: chartInfo.isshowvalue,
          mapGroupOneFieldLabelName: datas.mapGroupOneField
            ? datas.mapGroupOneField.labelName
            : "",
          mapGroupOneField: datas.mapGroupOneField,
          mapSummaryFieldLabelName: datas.mapSummaryField
            ? datas.mapSummaryField.labelName
            : "",
          mapSummaryWay: datas.mapSummaryField
            ? datas.mapSummaryField.summaryWay
            : "",
          objectLable: datas.objectInfo ? datas.objectInfo.label : "",
          reportName: datas.reportInfo ? datas.reportInfo.name : "",
          unit: chartInfo.unit,
        };
        this.showData.reportInfo = datas.reportInfo; // 获取报表基本信息
        this.showData.mapGroupTwoField = datas.mapGroupTwoField
          ? datas.mapGroupTwoField
          : {}; // 二级分组字段信息
        this.showData.mapSummaryField = datas.mapSummaryField; //=======
        this.showData.name = datas.reportInfo.name; // 获取图表名称
        this.showData.refreshDate = datas.refreshDate; //刷新时间

        this.showData.objectInfo = datas.objectInfo ? datas.objectInfo : ""; // 获取对象基本信息
        this.showData.viewInfo = datas.viewInfo ? datas.viewInfo : ""; // 获取视图基本信息
        this.showData.mapGroupOneField = datas.mapGroupOneField
          ? datas.mapGroupOneField
          : ""; // 一级分组字段信息
        this.showData.digitalData = datas.digitalData; // 数字图的数据
        if (value === "refresh") {
          this.$refs.loadimgbox.classList.remove("loadimg");
          this.isRefresh = false; //刷新按钮默认false;
        }
        // 双轴图类型
        if (this.showChartInfos.dashboardtype === "biaxialFigure") {
          this.showData.listAvgFields = datas.listAvgFields;
          this.showData.listMaxFields = datas.listMaxFields;
          this.showData.listMinFields = datas.listMinFields;
          this.showData.listSumFields = datas.listSumFields;
          this.showData.mapCountField = datas.mapCountField;
          this.showData.mapGroupOneField = datas.mapGroupOneField;
        }
        // 表格图表类型：没有分组tableDetail
        if (this.showChartInfos.dashboardtype === "tableDetail") {
          this.showData.tableData = datas.listChartData;
          if (datas.reportInfo) {
            // 报表数据源
            this.showData.sourceType = "report";
            let objParses = [];
            datas.reportInfo.listCheckedFields.map((item) => {
              if (item.schemefieldName !== "totalrecord") {
                objParses.push(item);
              }
            });
            this.showData.colData = objParses;
          }
          return;
        }
        // 表格图表类型：带有分组：report
        if (this.showChartInfos.dashboardtype === "report_summary") {
          this.showData.tableData = datas.listChartData;
          if (datas.reportInfo) {
            // 报表数据源
            this.showData.sourceType = "report";
            this.showData.colData = [];
            this.showData.colData.push(
              datas.mapGroupOneField,
              datas.mapGroupTwoField
            );
            this.showData.colData = this.showData.colData
              .concat(datas.listAvgFields)
              .concat(datas.listMaxFields)
              .concat(datas.listMinFields)
              .concat(datas.listSumFields);
            this.showData.colData = this.showData.colData.filter(
              (item) => item
            ); // 过滤掉空值
          }
          return;
        }
      } else {
        this.chartsLoading = false;
      }
    },
    // 查看报表,跳转到报表页
    seeReport() {
      this.$router.push({
        path: `/reportObject/editReport/run/${this.showData.reportInfo.id}`,
        query: {},
      });
    },
    // 刷新按钮
    fnrefresh() {
      // 首次点击刷新时，调取接口
      // 再次点击刷新时，间隔时间必须大于1分钟在调取接口
      // 在一分钟内点击刷新，刷新按钮单纯的转动
      if (!this.chartTimer) {
        this.chartTimer = new Date().getTime();
        this.$refs.loadimgbox.classList.add("loadimg");
        this.isRefresh = true;
        this.chartsLoading = true;
        this.getChartDataByReportForObjectDetail("refresh");
      } else {
        let nowTime = new Date().getTime();
        if (nowTime - this.chartTimer > 60000) {
          this.$refs.loadimgbox.classList.add("loadimg");
          this.isRefresh = true;
          this.chartsLoading = true;
          this.getChartDataByReportForObjectDetail("refresh");
          this.chartTimer = new Date().getTime();
        } else {
          this.$refs.loadimgbox.classList.add("loadimg");
          setTimeout(() => {
            this.$refs.loadimgbox.classList.remove("loadimg");
          }, 3000);
        }
      }
    },
  },
  mounted() {
    this.getChartDataByReportForObjectDetail();
  },
};
</script>
<style scoped lang="scss">
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 1s linear infinite;
}
.report-charts {
  display: flex;
  padding: 15px 12px;
  border: 1px solid #dddbda;
  border-radius: 3px;
  margin-top: 7px;
  flex-direction: column;
  .chart-box {
    height: 290px;
    width: 100%;
    margin-top: 10px;
    padding: 0 15px;
    // 设置表格图表宽度100%
    .content {
      ::v-deep table {
        width: 100% !important;
      }
    }
  }
  .report-name {
    font-size: 14px;
    font-weight: bold;
  }
  .see-report {
    font-size: 12px;
    color: #006dcc;
    margin-left: 5px;
    cursor: pointer;
  }
  .report-tip {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #888888;
    margin-top: 10px;
    .effect-btn {
      display: flex;
      i {
        cursor: pointer;
        font-size: 12px;
        margin: 0 3px;
      }
    }
    .effect-time {
      margin-right: 5px;
    }
  }
}
</style>