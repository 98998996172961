<!--
 * @Author: zhangyutong
 * @Date: 2021-09-23 16:09:37
 * @LastEditTime: 2021-10-25 11:54:53
 * @LastEditors: zhangyutong
 * @FilePath: \lightning-web\src\views\quotationEmail\components\selectTemplate.vue
-->
<!--  -->
<template>
  <div class="">
    <!-- 报价单预览页面 -->
    <el-form
      :status-icon="false"
      ref="fromFormat"
      :model="templateData"
      class="clearfix"
      :validate-on-rule-change="false"
      label-position="right"
      :rules="rules"
    >
      <el-dialog
        :title="$t('label.emailobject.templates')"
        ref="priceItem"
        :visible="selectTemplateDialogVisible"
        width="50%"
        top="300px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        :lock-scroll="true"
      >
        <div class="mainContent">
          <el-form-item
            :label="$t('label.emailobject.templates')"
            label-width="30%"
            style="min-height: 38px; width: 100%"
            prop="templateId"
          >
            <el-select
              v-model="templateData.templateId"
              :placeholder="$t('label.searchs')"
              style="width: 60%"
              size="mini"
            >
              <el-option
                v-for="item in templateList"
                :key="item.templateid"
                :label="item.templatename"
                :value="item.templateid"
                :disabled="item.isenable !== '1'"
              >
                <!-- :disabled="item.isenable !== '1'" -->
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('label.offfer.filer.name')"
            label-width="30%"
            style="min-height: 38px; width: 100%"
            prop="templateFile"
          >
            <el-input
              v-model="templateData.templateFile"
              style="width: 60%"
              size="mini"
            ></el-input>
          </el-form-item>
        </div>

        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-form-item class="btBox">
            <el-button size="mini" class="whiteBt" @click="handleClose">
              {{ $t("button_partner_cancel") }}
            </el-button>
            <el-button size="mini" type="primary" class="blueBt" @click="save">
              {{ $t("label.emailtocloudcc.button.sure") }}
            </el-button>
          </el-form-item>
        </div>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      rules: {
        templateId: [
          {
            required: true,
            message: this.$i18n.t("label.ccdocument.select.template.open"),
            trigger: "blur",
          },
        ],
        templateFile: [
          {
            required: true,
            message: this.$i18n.t("label.writer. name.file"),
            trigger: "blur",
          },
        ],
      },
      templateData: {
        templateId: "",
        templateFile: "",
        templateType: "",
      },
    };
  },
  props: {
    selectTemplateDialogVisible: {
      type: Boolean,
      default: true,
    },
    templateList: {
      type: Array,
      default: () => [],
    },
  },
  //方法集合
  methods: {
    init(fieldValue, id) {
      this.templateData.templateId = id;
      this.templateData.templateFile = fieldValue;
    },
    save() {
      this.$refs.fromFormat.validate((valid) => {
        if (valid) {
          this.$emit("closeSelectTemplateDialog", true, this.templateData);
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.$emit("closeSelectTemplateDialog");
    },
  },
};
</script>
<style lang='scss' scoped>
.mainContent {
  margin: 0px auto;
}
.btBox {
  margin-bottom: 0px;
}
</style>