<template>
  <div class="writeemailmain">
    <!-- 内容 -->
    <div class="emailContent">
      <!-- 发件人 -->
      <div class="contentItem">
        <!-- 左侧内容描述 -->
        <div class="itemLeftContent">
          <span>*</span>
          <span>
            <!-- 发件人 -->
            {{ $t("component.sendmail.label.fromuser") }}</span
          >
        </div>
        <!--右侧文本框 -->
        <div class="Receipter">
          <div class="my-multiselect Receipter">
            <el-select v-model="sender" ref="sender">
              <el-option
                v-for="item in senderList"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 收件人 -->
      <div class="contentItem">
        <!-- 左侧内容描述 -->
        <div class="itemLeftContent">
          <span>*</span>
          <span>
            <!-- 收件人 -->
            {{ $t("label.email.to") }}</span
          >
        </div>
        <!--右侧文本框 -->
        <div class="Receipter">
          <div class="my-multiselect Receipter">
            <el-select
              v-model="receiver"
              ref="Receipter"
              remote
              multiple
              placeholder=""
              default-first-option
              filterable
              :allow-create="createReceiver"
              :remote-method="remoteMethod"
              @keyup.enter.native="addmytag"
            >
              <el-option
                v-for="item in countries"
                ref="Receipter"
                :key="item.id"
                :label="item.label"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div class="bcc">
            <span @click="ccstatus = true">
              <!-- 抄送 -->
              {{ $t("label.email.cc") }}
            </span>
            <span @click="bccstatus = true">
              <!-- 密送 -->
              {{ $t("vue_label_commonobjects_detail_bcc") }}
            </span>
          </div>
        </div>
      </div>
      <!-- 抄送 -->
      <div v-if="ccstatus" class="contentItem">
        <div class="itemLeftContent">
          <span class="itemColor">
            <!-- 抄送 -->
            {{ $t("label.email.cc") }}
          </span>
        </div>
        <div class="Receipter cc">
          <el-select
            v-model="cc"
            ref="cc"
            remote
            multiple
            placeholder=""
            default-first-option
            filterable
            :allow-create="createCc"
            :remote-method="remoteMethod1"
            @keyup.enter.native="addmytag1"
          >
            <el-option
              v-for="item in countries1"
              :key="item.id"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 密送 -->
      <div v-if="bccstatus" class="contentItem">
        <div class="itemLeftContent">
          <span class="itemColor">
            <!-- 密送 -->
            {{ $t("vue_label_commonobjects_detail_bcc") }}
          </span>
        </div>
        <div class="Receipter bccemail">
          <el-select
            v-model="bcc"
            ref="bcc"
            remote
            multiple
            placeholder=""
            default-first-option
            filterable
            :allow-create="createBcc"
            :remote-method="remoteMethod2"
            @keyup.enter.native="addmytag2"
          >
            <el-option
              v-for="item in countries2"
              :key="item.value"
              :label="item.label"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 主题 -->
      <div class="contentItem">
        <div class="itemLeftContent">
          <span>*</span>
          <span>
            <!-- 主题 -->
            {{ $t("label.ems.subject") }}
          </span>
        </div>
        <div class="Receipter">
          <!-- 请填写主题 -->
          <el-input
            v-model="theme"
            :placeholder="$t('label.weixin.input.subject')"
          ></el-input>
        </div>
      </div>
      <!-- 富文本框 -->
      <div class="content">
        <div class="wangeditor" v-if="emailIsOperation && isText == 'false'">
            <wangeditor ref="wangeditor" @editorContent="editorContent">
            </wangeditor>
        </div>
        <div v-if="emailIsOperation && isText == 'true'" style="width: 100%">
          <el-input type="textarea" :rows="8" v-model="textarea"></el-input>
        </div>
      </div>
      <!-- 发票支付样式图 -->
      <div
        style="
          width: 100%;
          border: 1px solid #dcdfe6;
          padding: 30px;
          color: #737373;
          margin-top: 10px;
        "
        v-if="!emailIsOperation"
      >
        <div>
          <input
            type="text"
            v-model="invoiceName"
            style="
              border: none;
              font-size: 12px;
              outline: none;
              color: #737373;
              width: 100%;
            "
          />
        </div>
        <div style="margin-bottom: 10px">
          <input
            type="text"
            v-model="invoiceRemarks"
            style="
              border: none;
              font-size: 12px;
              outline: none;
              color: #737373;
              width: 100%;
            "
          />
        </div>
        <div
          style="
            width: 100%;
            background: #f3f8fe;
            padding: 30px;
            text-align: center;
          "
        >
          <div style="font-weight: bold; font-size: 16px; color: #333333">
            {{ $t("front-email-payment-Invoice-amount") }}
          </div>
          <div style="font-weight: bold; font-size: 18px; color: #ff9e2c">
            {{ invoicesData.currency }}{{ invoicesData.total }}
          </div>
          <div
            style="
              width: 100%;
              height: 2px;
              background: #7db5e9;
              margin: 20px 0;
            "
          ></div>
          <div style="text-align: left; width: 200px; display: inline-block">
            <div>
              <span>{{ $t("front-email-payment-Invoice-number") }}：</span
              ><span style="color: #333333; margin-left: 30px">{{
                invoicesData.invoicenumber
              }}</span>
            </div>
            <div>
              <span>{{ $t("front-email-payment-billing-date") }}：</span
              ><span style="color: #333333; margin-left: 30px">{{
                invoicesData.invoiceDate
              }}</span>
            </div>
            <div style="margin-bottom: 20px">
              <span>{{ $t("label.quickbooks.viewlist.duedate") }}：</span
              ><span style="color: #333333; margin-left: 30px">{{
                invoicesData.dueDate
              }}</span>
            </div>
          </div>
          <a
            href="JavaScript:;"
            style="
              line-height: 35px;
              background: #006dd3;
              color: white;
              border-radius: 3px;
              text-decoration: none;
              display: block;
              width: 144px;
              height: 35px;
              margin: 0 auto;
            "
            >{{ $t("front-email-payment-immediate-payment") }}</a
          >
        </div>
        <div style="font-weight: bold; color: #333333; margin-top: 10px">
          <input
            type="text"
            v-model="thankWord"
            style="
              border: none;
              font-size: 12px;
              outline: none;
              color: #737373;
              width: 100%;
            "
          />
        </div>
        <div>
          <input
            type="text"
            v-model="invoicesData.company"
            style="
              border: none;
              font-size: 12px;
              outline: none;
              color: #737373;
              width: 100%;
            "
          />
        </div>
        <div>
          <input
            type="text"
            v-model="invoicesData.username"
            style="
              border: none;
              font-size: 12px;
              outline: none;
              color: #737373;
              width: 100%;
            "
          />
        </div>
      </div>
      <div class="files">
        <ul>
          <li v-for="(item, index) in enclosurearr" :key="item.fileContentId">
            <div class="enclosurebox">
              <div class="enclosureicon">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-file1"></use>
                </svg>
              </div>
              <span>{{ item.name }}</span>
            </div>
            <i class="el-icon-close" @click="deletefile(index)"></i>
          </li>
        </ul>
      </div>
      <div class="toolbar" v-if="emailIsOperation">
        <ul>
          <li @click="tabeyescolor">
            <el-tooltip
              class="item"
              effect="dark"
              :content="eyes.title"
              placement="top"
            >
              <div class="toolbaricon">
                <svg class="icon" aria-hidden="true">
                  <use
                    :href="
                      eyes.istabimg == true ? eyes.changeimgurl : eyes.imgurl
                    "
                  ></use>
                </svg>
              </div>
            </el-tooltip>
          </li>
          <li
            v-for="(item, index) in toolbarOption"
            :key="index"
            @click="tabtoolbar(item.name)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.title"
              placement="top"
            >
              <div class="toolbaricon">
                <svg class="icon" aria-hidden="true">
                  <use
                    :href="
                      item.istabimg == true ? item.changeimgurl : item.imgurl
                    "
                  ></use>
                </svg>
              </div>
            </el-tooltip>
          </li>
        </ul>
        <!-- 添加附件 -->
        <div class="addfile">
          <addFiles
            ref="atoLib"
            :isFromFileCenter="false"
            :dialogAddVisible="toolbarOption[0].istabimg"
            @close="closedailog"
            @addSubmit="addenclosure"
          ></addFiles>
        </div>
        <!-- 添加字段 -->
        <!-- 添加字段 -->
        <div>
          <addEmailFields
            :istabimg="toolbarOption[1].istabimg"
            @closedailogFields="closedailogFields"
            @insertFieldsMethod="insertFieldsMethod"
          ></addEmailFields>
        </div>
        <!-- 管理模板 -->
           <email-template-front ref="emailTemplateFront" @deliverTemplate="deliverTemplate"  :showRelateId="showRelateid"></email-template-front>
        <!-- 签名 -->
        <emailSign
          v-if="toolbarOption[3].istabimg"
          @closedialogSign="closedialogSign"
        ></emailSign>
      </div>
      <!-- 插入字段失败提示 -->
      <div>
        <!-- 提示 -->
        <el-dialog
          :title="$t('label.prompt')"
          :visible.sync="errdialog"
          width="40%"
          :append-to-body="true"
        >
          <span>
            <!-- 当前邮件正文中插入的字段与已关联的记录不匹配，可能会造成取值失败。 -->
            {{ $t("label.emailobject.template.message2") }}
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errdialog = false">
              <!-- 放弃 -->
              {{ $t("label.emailobject.template.giveup") }}
            </el-button>
            <el-button type="primary" @click="Continueadd">
              <!-- 继续添加 -->
              {{ $t("label.emailobject.template.insert.continue") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
      <!-- 相关项 -->
      <div class="relatedItem" v-if="isShowRelated">
        <!-- 文本内容 -->
        <div class="text">相关项</div>
        <div class="relatedContent">
          <relavent-select ref="relaventSelect" :relevent="relevent"></relavent-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import relaventSelect from "@/components/Email/relaventSelect";
import addEmailFields from "@/components/Email/addEmailFields.vue";
import addFiles from "@/components/AddFiles/AddFiles";
import emailSign from "@/components/Email/emailSign.vue";
import wangeditor from "@/components/emailEditor";
import updateTemplate from "@/components/Email/updateTemplate.vue";
import * as Emailobject from "./api.js";
import * as emailApi from "./api.js";
import { transformTimeDay } from "@/utils/time.js";
import emailTemplateFront from "@/components/Email/email-template-front.vue";

export default {
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: false,
    },
    enclosure: {
      type: Array,
      default: () => ([]),
    },
    emailIsOperation: {
      type: Boolean,
      default: true,
    },
    templateId: {
      type: String,
      default: "",
    },
    recordId: {
      type: String,
      default: "",
    },
    objId: {
      type: String,
      default: "",
    },
    invoiceReceiver: {
      type: String,
      default: "",
    },
    //是否显示相关项
    isShowRelated: {
      type: Boolean,
      default: true,
    },
    //相关项是否可编辑
    relevent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textarea:"",
      isText:"false",
      showRelateid:"",
      //感谢语
      thankWord: this.$i18n.t("front-email-payment-thanks"),
      //发票邮件称谓
      invoiceName: this.$i18n.t("label.sendsms.idea"),
      //发票邮件备注
      invoiceRemarks: this.$i18n.t("front-email-payment-describe"),
      errdialog: false,
      sender: "",
      receiverFilterable: true,
      //插入字段
      insertFields: "",
      seahcvalue: "",
      countries: [],
      countries1: [],
      countries2: [],
      senderList: [],
      ccstatus: false,
      bccstatus: false,
      theme: "",
      editorText: "",
      receiver: [],
      cc: [],
      ccAddress: [],
      bcc: [],
      bccAddress: [],
      firstOption: true,
      relateid: "",
      eyes: {
        name: "eyes",
        // 邮件追踪
        title: this.$i18n.t("label.emailobject.trackemailopening"),
        istabimg: true,
        imgurl: "#icon-show",
        changeimgurl: "#icon-show_blue",
      },
      toolbarOption: [
        {
          name: "enclosure",
          // 添加附件
          title: this.$i18n.t("component_chatter_post_button_file"),
          istabimg: false,
          imgurl: "#icon-addassembly",
          changeimgurl: "#icon-addassemblyblue",
        },
        {
          name: "InsertField",
          // 添加字段
          title: this.$i18n.t("label.addfield"),
          istabimg: false,
          imgurl: "#icon-insert",
          changeimgurl: "#icon-insertblue",
        },
        {
          name: "Template",
          // 选择模板
          title: this.$i18n.t("label.select.template"),
          istabimg: false,
          imgurl: "#icon-shezhi_02",
          changeimgurl: "#icon-shezhi_02_blue",
        },
        {
          name: "signature",
          // 编辑签名
          title: this.$i18n.t("label.emailobject.signature"),
          istabimg: false,
          imgurl: "#icon-editor",
          changeimgurl: "#icon-editorblue",
        },
      ],
      //上传附件文件展示列表
      enclosurearr: [],
      //获取签名
      emailSignval: "",
      // 允许创建收件人
      createReceiver: false,
      // 允许创建抄送人
      createCc: false,
      // 允许创建密送人
      createBcc: false,
      // 发件箱类型
      ccType: "",
      // 发件箱地址
      fromaddress: "",
      //判断是否绑定邮箱
      emailOpen: false,
      //发票邮件信息
      invoicesData: {},
    };
  },
  components: {
    wangeditor,
    addFiles,
    addEmailFields,
    updateTemplate,
    emailSign,
    relaventSelect,
    emailTemplateFront
  },
  watch: {
    editorText(val) {
      this.editorText = val;
    },
    enclosure(val) {
      this.enclosurearr = typeof(this.enclosure) == 'object' ? [...this.enclosure] : this.enclosure;
    },
  },
  mounted() {
    this.enclosurearr = typeof(this.enclosure) == 'object' ? [...this.enclosure] : this.enclosure;
    //更改签名启用状态
    this.$Bus.$on("deliver-email-sign", this.deliverEmailSignCallback);
    
    //更改签名
    this.$Bus.$on("deliver-change-sign", this.deliverChangesSignCallback );
    //接收相关项id
    this.$Bus.$on("deliver-relateid", this.deliverRelateidCallback);
    //接收字段id
    this.$Bus.$on("deliver-insert-fields", this.deliverInsertFieldsCallback);
    //接收模板
    this.$Bus.$on("deliver-new-template", this.deliverNewTemplate);
    if (!this.emailIsOperation) {
      this.getMessageByNum();
    }
    if (this.invoiceReceiver != "") {
      this.receiver = [this.invoiceReceiver];
    }
    //其他组件调用发送邮件方法
    this.$Bus.$on("send-Email", this.sendEmailCallback);
    //其他组件调用转发方法
    this.$Bus.$on("forward-send-Email", this.forwardSendEmailCallback);
    this.getEmailObjectGetEmailPageInfos()
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-email-sign", this.deliverEmailSignCallback);
    
    //更改签名
    this.$Bus.$off("deliver-change-sign", this.deliverChangesSignCallback );
    //接收相关项id
    this.$Bus.$off("deliver-relateid", this.deliverRelateidCallback);
    //接收字段id
    this.$Bus.$off("deliver-insert-fields", this.deliverInsertFieldsCallback);
    //接收模板
    this.$Bus.$off("deliver-new-template", this.deliverNewTemplate);
    //其他组件调用发送邮件方法
    this.$Bus.$off("send-Email", this.sendEmailCallback);
    //其他组件调用转发方法
    this.$Bus.$off("forward-send-Email", this.forwardSendEmailCallback);
  },
  methods: {
    forwardSendEmailCallback() {},
    sendEmailCallback(status) {
      this.sendEmail(status);
    },
    deliverNewTemplate (res) {
      this.$refs.wangeditor.clearvalue();
      this.theme = res.subject;
      this.$refs.wangeditor.signContent(res.content + this.emailSignval);
      this.toolbarOption[2].istabimg = false;
    },
    deliverRelateidCallback(res) {
      this.relateid = res;
    },
    deliverInsertFieldsCallback(res) {
      this.insertFields = res;
    },
    deliverEmailSignCallback(res){
      this.$refs.wangeditor.clearvalue();
      this.$refs.wangeditor.signContent(res);
      this.toolbarOption[3].istabimg = false;
    },
    deliverChangesSignCallback(res){
      this.$refs.wangeditor.clearvalue();
      this.$refs.wangeditor.signContent(res);
      this.toolbarOption[3].istabimg = false;
    },
    //手动关闭el-select下拉选项
    closeSelect(){
      this.$refs.sender.blur();
    },
    // 打开邮件模板弹窗
    openTemplateBox() {
      this.$refs.emailTemplateFront.showDialog("email")
      this.showRelateid = this.$refs.relaventSelect.showRelateId
    },
    deliverTemplate(items,data){
      this.theme = data.subject;
       this.isText = items.isText;
      if (items.isText == "false") {
        this.$nextTick(() => {
          this.$refs.wangeditor.clearvalue();
          this.$refs.wangeditor.signContent(
            data ? data.content : "",data.content.indexOf("{!") != -1
          );
        });
      }else{
        this.textarea = "";
        this.textarea += data.content;
      }
      // this.$refs.wangeditor.clearvalue();
      // this.theme = result.subject;
      // this.$refs.wangeditor.signContent(result.content,result.content.indexOf("{!") != -1);
    },
    //根据发票id获取邮件信息
    async getMessageByNum() {
      let res = await emailApi.getMessageByNum({ invoiceid: this.recordId });
      if (res.result) {
        this.invoicesData = res.data;
        this.invoicesData.invoiceDate =
          this.invoicesData.invoiceDate != null
            ? transformTimeDay(Number(this.invoicesData.invoiceDate))
            : "";
        this.invoicesData.dueDate =
          this.invoicesData.dueDate != null
            ? transformTimeDay(Number(this.invoicesData.dueDate))
            : "";
        this.invoicesData.total =
          this.invoicesData.total != null ? this.invoicesData.total : "0.00";
        this.invoicesData.accountname =
          this.invoicesData.accountname != null
            ? this.invoicesData.accountname
            : "";
        this.invoiceName = `${this.$i18n.t("label.sendsms.idea")}${
          this.invoicesData.accountname
        }：`;
      }
    },
    editorContent(val) {
      this.editorText = val;
    },
    //获取邮件信息
    async getEmailObjectGetEmailPageInfos() {
      let params = {
        recordId: this.$route.params.id || this.$route.query.caseId,
      };
      let res = await emailApi.getEmailObjectGetEmailPageInfo(params);
      if (res.result) {
        //发件人
        if (res.data.emaillist && res.data.emaillist[0]) {
          // 收件人回显---如果为个案，有多个发件人情况
          if(res.data.emaillist.length > 1){
            res.data.emaillist.forEach((item)=>{
               let obj = {}
               obj.label = item.syncmail;
               this.senderList.push(obj)
            })
          }else{
            this.senderList = [
            {
              label: res.data.emaillist[0].syncmail,
            },
          ];
          }
          this.sender = res.data.emaillist[0].syncmail;
          this.fromaddress = res.data.emaillist[0].syncmail;
          this.ccType = res.data.emaillist[0].cctype;
        }
        if (res.data.relatedActiveMailSetting) {
          this.emailOpen = true;
        }
        this.emailSignval =
          res.data.signContent != null && res.data.signContent != undefined
            ? res.data.signContent
            : "";
        if (this.emailIsOperation) {
          this.$refs.wangeditor.signContent(this.emailSignval);
        }
      }
    },
    async sendEmail(status) {
      if (this.emailOpen == false) {
        this.$message.warning(
          this.$i18n.t("vue_label_email_You_have_not_activated_the_mailbox")
        ); //您还未启用邮箱
        return false;
      }
      this.receiver.map((item, index) => {
        if (item.indexOf("+") !== -1) {
          this.receiver[index] = item.split("+")[0];
        }
      });
      let array = JSON.parse(JSON.stringify(this.receiver));
      array.map((item, index) => {
        if (item.indexOf("+") !== -1) {
          array[index] = item.split("+")[0];
        }
      });
      let relatedidse = JSON.parse(JSON.stringify(this.receiver));
      let relatedidsArray = [];
      relatedidse.map((item, index) => {
        if (item.indexOf("+") !== -1) {
          relatedidsArray.push(item.split("+")[1]);
        }
      });
      //拼接收件人邮箱
      let getEmailId = array.length == 0 ? "" : array.join(";");
      let relatedids;
      if (relatedidsArray.length != 0) {
        relatedids = relatedidsArray.join(",");
      }
      let ccliststr = this.cc.length == 0 ? "" : this.cc.join(";"); // //拼接密送人邮箱
      let bccliststr = this.bcc.length == 0 ? "" : this.bcc.join(";"); //拼接文件id
      let filelist = [];
      let havefile;
      let filedstr;
      if (this.enclosurearr.length != 0) {
        this.enclosurearr.forEach((fileval) => {
          filelist.push(fileval.fileContentId);
        });
        filedstr = filelist.join(",");
        havefile = "1";
      } else {
        filedstr = "";
        havefile = "0";
      } //邮件发送状态码
      let savetempnum;
      let singleSendnum;
      let messageval;
      if (status == "0") {
        savetempnum = "1";
        singleSendnum = ""; // 保存成功
        messageval = this.$i18n.t("label.search.saveok");
      } else if (status == "1") {
        savetempnum = "";
        singleSendnum = ""; // 发送邮件成功
        messageval = this.$i18n.t("label.emailobject.emailsent");
      } else if (status == "2") {
        savetempnum = "";
        singleSendnum = "1"; //分别发送邮件成功
        messageval = this.$i18n.t("vue_label_email_send_emails_separately");
      } //表单验证
      if (this.receiver.length == 0 && status != "0") {
        this.$message({
          showClose: true,
          type: "warning", // 请选择收件人
          message: this.$i18n.t("label.email.recipients.select"),
        });
        return false;
      }
      if (this.theme == "") {
        this.$message({
          showClose: true,
          type: "warning", // 请输入邮件主题
          message: this.$i18n.t("vue_label_email_please_enter_email_subject"),
        });
        return false;
      }
      if (this.emailIsOperation && this.editorText == "" && this.textarea == "") {
        //请输入邮件内容
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t(
            "vue_label_email_please_enter_the_message_content"
          ),
        });
        return false;
      }
      let option = {
        subject: this.theme,
        toaddress: getEmailId,
        ccaddress: ccliststr,
        bccaddress: bccliststr,
        content: this.emailIsOperation
          ? this.editorText
          : `<div style="width:100%;border:1px solid #dcdfe6;padding:30px;color:#737373;margin-top:10px;box-sizing: border-box"><div>${
              this.invoiceName
            }</div><div style="margin-bottom:10px">${
              this.invoiceRemarks
            }</div><div style="width:100%;background:#f3f8fe;padding:30px;text-align:center;box-sizing: border-box"><div style="font-weight:bold;font-size:16px;color:#333333">${this.$i18n.t(
              "front-email-payment-Invoice-amount"
            )}</div><div style="font-weight:bold;font-size:18px;color:#FF9E2C">${
              this.invoicesData.currency
            }${
              this.invoicesData.total
            }</div><div style="width:100%;height:2px;background:#7db5e9;margin:20px 0"></div><div style="text-align:left;width:200px;display: inline-block;"><div><span>${this.$i18n.t(
              "front-email-payment-Invoice-number"
            )}：</span><span style="color:#333333;margin-left:30px">${
              this.invoicesData.invoicenumber
            }</span></div><div><span>${this.$i18n.t(
              "front-email-payment-billing-date"
            )}：</span><span style="color:#333333;margin-left:30px">${
              this.invoicesData.invoiceDate
            }</span></div><div style="margin-bottom: 20px"><span>${this.$i18n.t(
              "label.quickbooks.viewlist.duedate"
            )}：</span><span style="color:#333333;margin-left:30px">${
              this.invoicesData.dueDate
            }</span></div></div><a href="${
              location.origin
            }/#/promptlyPayment?templateId=${this.templateId}&recordId=${
              this.recordId
            }&objId=${this.objId}&loginName=${
              this.$store.state.userInfoObj.loginName
            }&customerName=${
              this.invoicesData.accountname
            }&userId=${this.$store.state.userInfoObj.userId}&orgId=${
              this.$store.state.userInfoObj.orgId
            }&fileId=${filedstr}&total=${this.invoicesData.total}&dueDate=${
              this.invoicesData.dueDate
            }&invoiceNumber=${this.invoicesData.invoicenumber}&currency=${
              this.invoicesData.currency
            }&invoiceDate=${this.invoicesData.invoiceDate}&language=${
              this.$store.state.userInfoObj.language
            }" target="_blank" style="line-height:35px;background:#006dd3;color:white;border-radius:3px;text-decoration: none;display: block;width: 144px;height: 35px;margin: 0 auto;">${this.$i18n.t(
              "front-email-payment-immediate-payment"
            )}</a></div><div style="font-weight:bold;color:#333333;margin-top:10px">${
              this.thankWord
            }</div><div>${this.invoicesData.company}</div><div>${
              this.invoicesData.username
            }</div></div>`,
        isattachment: havefile,
        attachment: filedstr,
        istrackopen: this.eyes.istabimg == true ? "1" : "0",
        relateid: this.relateid,
        relatedid: relatedids,
        savetemp: savetempnum,
        singleSend: singleSendnum,
      };
      if(this.emailIsOperation && this.isText=="true"){
        option.content=this.textarea
      }
      this.$emit("changeButtonLoading");
      let result = await Emailobject.sendemail(option);
      if (result.result == true) {
        this.$emit("buttonLoadingChange");
        this.$message({
          showClose: true,
          type: "success",
          message: messageval,
        });
        this.theme = "";
        this.editorText = "";
        this.isBatch = false; // this.userList = [];
        this.seahcvalue = [];
        this.cc = [];
        this.bcc = [];
        this.enclosurearr = [];
        relatedids = [];
        //判断是普通邮件发送清空收件人，发票不清空
        if (this.emailIsOperation) {
          this.receiver = [];
        }
        if (this.emailIsOperation) {
          this.$refs.wangeditor.clearvalue();
        }
        this.$emit("closeEmailDialog", false);
        this.$emit("changeInvoiceStatus", !this.emailIsOperation);
      }
    },
    remoteMethod(query) {
      if (query === "") {
        return;
      }
      this.seahcvalue = query;
      let str = query.replace(/'/g, "");
      Emailobject.seachemail({
        searchKey: str,
      }).then((res) => {
        if (res.data != null) {
          let peoplearr = [];
          res.data.forEach((item) => {
            let obj = {};
            obj.name = item.email + "+" + item.id;
            obj.value = item.name;
            obj.label = item.name + "<" + item.email + ">";
            obj.id = item.id;
            obj.objid = item.objid;
            if (item.objid == "lead") {
              obj.src = "";
            }
            peoplearr.push(obj);
          });
          this.countries = peoplearr;
        } else {
          this.countries = [];
        }
      });
    },
    remoteMethod1(query) {
      this.seahcvalue = "";
      this.seahcvalue = query;
      let str = query.replace(/'/g, "");
      Emailobject.seachemail({
        searchKey: str,
      }).then((res) => {
        if (res.data != null) {
          let peoplearr = [];
          res.data.forEach((item) => {
            let obj = {};
            obj.name = item.email;
            obj.value = item.name;
            obj.label = item.name + "<" + item.email + ">";
            obj.id = item.id;
            obj.objid = item.objid;
            if (item.objid == "lead") {
              obj.src = "";
            }
            peoplearr.push(obj);
          });
          this.countries1 = peoplearr;
        } else {
          this.countries1 = [];
        }
      });
    },
    remoteMethod2(query) {
      this.seahcvalue = "";
      this.seahcvalue = query;
      let str = query.replace(/'/g, "");
      Emailobject.seachemail({
        searchKey: str,
      }).then((res) => {
        if (res.data != null) {
          let peoplearr = [];
          res.data.forEach((item) => {
            let obj = {};
            obj.name = item.email;
            obj.value = item.name;
            obj.label = item.name + "<" + item.email + ">";
            obj.id = item.id;
            obj.objid = item.objid;
            if (item.objid == "lead") {
              obj.src = "";
            }
            peoplearr.push(obj);
          });
          this.countries2 = peoplearr;
        } else {
          this.countries2 = [];
        }
      });
    },
    //手动填入邮箱
    addmytag() {
      if (this.countries.length === 0) {
        let reg =
          /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (this.seahcvalue != "") {
          // 正则表达式  测试输入的值是否符合格式
          if (reg.test(this.seahcvalue)) {
            let istrue = this.receiver.some((item) => {
              // 如果收件人的name==已存在
              return item.name == this.seahcvalue;
            });
            if (istrue == false) {
              this.receiver.push(this.seahcvalue);
            } else {
              // 该邮箱已添加
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_email_benn_added"),
                type: "error",
              });
            }
          } else {
            this.$message({
              showClose: true,
              // 请输入正确的电子邮箱地址
              message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
              type: "error",
            });
          }
        }
      }
    },
    //抄送手动填入邮箱
    addmytag1() {
      let reg =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (this.seahcvalue != "") {
        if (reg.test(this.seahcvalue)) {
          let istrue = this.cc.some((item) => {
            return item.name == this.seahcvalue;
          });
          if (istrue == false) {
            this.createCc = true;
            this.cc.push(this.seahcvalue);
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"),
              type: "error",
            });
          }
        } else {
          this.$message({
            showClose: true,
            // 请输入正确的电子邮箱地址
            message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
            type: "error",
          });
        }
      }
    },
    //密送手动填入邮箱
    addmytag2() {
      let reg =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (this.seahcvalue != "") {
        if (reg.test(this.seahcvalue)) {
          let istrue = this.bcc.some((item) => {
            return item.name == this.queryvalue2;
          });
          if (istrue == false) {
            this.createBcc = true;
            this.bcc.push(this.seahcvalue);
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"),
              type: "error",
            });
          }
        } else {
          this.$message({
            showClose: true,
            // 请输入正确的电子邮箱地址
            message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
            type: "error",
          });
        }
      }
    },
    //工具栏
    tabtoolbar(name) {
      if (name == "signature") {
        this.$Bus.$emit("deliver-signature", true);
      }
      if(name == "Template"){
        this.openTemplateBox()
      }
      this.toolbarOption.forEach((item) => {
        //判断是否为邮件追踪项
        if (item.name == name && item.name != "eyes") {
          item.istabimg = true;
        } else {
          item.istabimg = false;
        }
        //邮件追踪项则切换选中状态
        if (item.name == "eyes") {
          item.istabimg = !item.istabimg;
        }
        if (item.name == "enclosure") {
          this.$refs.atoLib.initPage();
        }
      });
    },
    //是否开启追踪
    tabeyescolor() {
      this.eyes.istabimg = !this.eyes.istabimg;
    },
    //富文本编辑器
    onContentChange(val) {
      this.editorText = val;
    },
    //关闭文件附件弹框
    closedailog() {
      this.toolbarOption[0].istabimg = false;
    },
    //添加附件
    addenclosure(params) {
      this.toolbarOption[0].istabimg = false;
      this.enclosurearr = [];
      params.forEach((item) => {
        this.enclosurearr.push(item);
      });
    },
    //删除附件方法
    deletefile(index) {
      this.enclosurearr.splice(index, 1);
    },
    //关闭添加字段弹框
    closedailogFields() {
      this.toolbarOption[1].istabimg = false;
    },
    //关闭模板弹框
    closeTemplate() {
      this.toolbarOption[2].istabimg = false;
    },
    //关闭模板弹框
    closedialogSign() {
      this.toolbarOption[3].istabimg = false;
    },
    //插入字段
    async insertFieldsMethod() {
      if (this.insertFields == "") {
        //请选择插入字段
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_email_insert_field"),
        });
        return false;
      }
      if (this.relateid) {
        let result = await Emailobject.insertFields({
          filedstr: `{${this.insertFields}}`,
          recordId: this.relateid,
        });
        if (result.data == null) {
          this.errdialog = true;
        } else {
          this.$refs.wangeditor.signContent(result.data);
        }
      } else {
        this.errdialog = true;
      }
      this.toolbarOption[1].istabimg = false;
    },
    //添加字段取值失败后，继续添加字段
    Continueadd() {
      this.errdialog = false;
      let fieldstr = `<span style="color:red">{${this.insertFields}}</span>`;
      // this.editorText = this.editorText + fieldstr;
      this.$refs.wangeditor.signContent(fieldstr);
    },
    //转发邮件(回显值)
    forwardEmail(params, relatedId) {
      //相关项
      this.relateid = relatedId;
      this.theme =
        this.$i18n.t("label.emailobject.emaildetail.button.forward") +
        ":" +
        params.name;
      this.$refs.wangeditor.signContent(params.htmlbody);
      let MessageBody = `
         <div>
        </br>
        </br>
        </br>
        </br>
        <div
          contenteditable="true"
          style="width: 100%;text-align:left;"
        >
          <p>-------------------------------------------------------</p>
          <p>${this.$i18n.t("label.senduser")}:${emailvalue.fromaddress}</p>
          <p>${this.$i18n.t("component.sendmail.label.touser")}:${
        emailvalue.toaddress
      }</p>
          <p ${emailvalue.ccaddress === "" ? false : true}>${this.$i18n.t(
        "label.mobile.phone.cc.people"
      )}:${emailvalue.ccaddress}</p>
          <p>${this.$i18n.t("vue_label_email_cc")}:${
        emailvalue.bccaddress === "" || emailvalue.bccaddress === undefined
          ? ""
          : emailvalue.bccaddress
      }</p>
          <p>${this.$i18n.t("component.sendmail.label.subject")}:${
        emailvalue.name
      }</p>
          <div style="letter-spacing: 0px;
    text-align: justify;
    white-space: pre-line">${emailvalue.htmlbody}</div>
        </div>
      </div>`;
      this.$refs.wangeditor.signContent(MessageBody);
      if (params.attachment) {
        let filearr = [];
        params.attachment.forEach((item) => {
          let obj = {};
          obj.id = item.file_info_id;
          obj.name = item.file_name;
          filearr.push(obj);
        });
        this.enclosurearr = filearr;
      }
    },
    //回复邮件(值回显)
    replyEmail(params, relatedId, isEmail) {
      //相关项 
        this.relateid = relatedId;
      //有邮件标题则回显邮件其他值
      if (params.fromaddress != "") { 
        this.receiver = [];
        this.receiver = params.fromaddress.split(",");
        this.receiverAddress = params.fromaddress.split(",");
      }
      if (params.name) {
        let emailvalue = params;
        //回复 label.emailobject.emaildetail.button.reply
        //密送人 vue_label_email_cc
        //主题 component.sendmail.label.subject
        this.theme =
          this.$i18n.t("label.emailobject.emaildetail.button.reply") +
          `:` +
          emailvalue.name;
        this.editorText = emailvalue.htmlbody;
        let MessageBody = `
         <div>
        </br>
        </br>
        </br>
        </br>
        <div
          contenteditable="true"
          style="width: 100%;text-align:left;"
        >
          <p>-------------------------------------------------------</p>
          <p>${this.$i18n.t("label.senduser")}:${emailvalue.fromaddress}</p>
          <p>${this.$i18n.t("component.sendmail.label.touser")}:${
          emailvalue.toaddress
        }</p>
          <p ${emailvalue.ccaddress === "" ? false : true}>${this.$i18n.t(
          "label.mobile.phone.cc.people"
        )}:${emailvalue.ccaddress}</p>
          <p>${this.$i18n.t("vue_label_email_cc")}:${
          emailvalue.bccaddress === "" || emailvalue.bccaddress === undefined
            ? ""
            : emailvalue.bccaddress
        }</p>
          <p>${this.$i18n.t("component.sendmail.label.subject")}:${
          emailvalue.name
        }</p>
          <div style="letter-spacing: 0px;
    text-align: justify;
    white-space: pre-line">${emailvalue.htmlbody}</div>
        </div>
      </div>`;
        this.$refs.wangeditor.signContent(MessageBody);
        //当前会话为邮件且有抄送人
        if (isEmail && emailvalue.ccaddress != "") {
          this.cc = [];
          this.cc = emailvalue.ccaddress.split(",");
          this.ccstatus = true;
        } else {
          this.cc = [];
          this.ccstatus = false;
        }
        if (emailvalue.attachment) {
          let filearr = [];
          emailvalue.attachmentList.forEach((item) => {
            let obj = {};
            obj.id = item.file_info_id;
            obj.name = item.file_name;
            filearr.push(obj);
          });
          this.enclosurearr = filearr;
        }
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.writeemailmain {
  width: 100%;
  background: #ffffff;
  .toolbar {
    margin: 24px 0 10px 0;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      li {
        border: 1px solid #dcdcdc;
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        .toolbaricon {
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .files {
    width: 100%;
    margin-top: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      li:nth-child(4n + 1) {
        margin-left: 0;
      }
      li {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f2f2;
        padding: 10px 10px;
        border-radius: 3px;
        margin-bottom: 10px;
        margin-left: 13px;
        .enclosurebox {
          display: flex;
          align-items: center;
          width: 70%;
        }
        span {
          font-size: 14px;
          color: #006dcc;
          margin-right: 10px;
          max-width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .enclosureicon {
      margin-right: 10px;
      .icon {
        font-size: 24px;
      }
    }
  }
  ::v-deep .w-e-toolbar {
    z-index: 200 !important;
  }
}
.headerTitle {
  font-size: 18px;
  font-weight: bold;
  color: #1d2033;
}
.emailHeader {
  height: 60px;
  padding: 0px 15px 0px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
}
.emailContent {
  // padding: 28px 36px 10px 36px;
  background: white;
}
.contentItem {
  white-space: nowrap;
  margin-top: 15px;
  height: 55px;
  display: flex;
  align-items: center;
}
.contentItem span:nth-child(1) {
  color: #db392e;
}
.Receipter {
  position: relative;
  width: 100%;
  .bcc {
    position: absolute;
    top: 12px;
    right: 16px;
    z-index: 9;
    span {
      margin-left: 10px;
      cursor: pointer;
      color: #006dcc;
    }
  }
  ::v-deep .multiselect__select {
    display: none;
  }
  ::v-deep .multiselect__tags {
    height: 40px;
  }
}
.itemColor {
  color: #2c3e50 !important;
  margin-left: 10px;
}
.itemLeftContent {
  font-weight: bold;
  width: 60px;
  text-align: left;
  margin-right: 15px;
}
::v-deep .el-input__inner {
  height: 40px;
}
.wangeditor {
  width: 100%;
}
.content {
  display: flex;
  margin-top: 25px;
}
::v-deep .el-select {
  width: 100%;
}
::v-deep .w-e-toolbar {
  z-index: 999 !important;
}
::v-deep .contentItem .el-select .el-input .el-select__caret {
  display: none;
}
.relatedItem {
  margin-top: 20px;
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px;
  }
  margin-bottom: 42px;
}
::v-deep .relatedContent .el-input__inner {
  height: 30px;
}
.btnGroup {
  padding-right: 15px;
  border-top: 1px solid #dcdcdc;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .makeSure {
    background: #005fb2;
    color: white;
    margin-left: 10px;
  }
}
.btnGroup button {
  height: 30px;
  padding: 7px 15px;
  border-radius: 3px;
  background: white;
  border: 1px solid #dcdcdc;
}
::v-deep .el-popper[x-placement^="bottom"] {
  max-height: 300px;
  overflow: auto;
}
::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #1890ff;
  color: #fff;
}
::v-deep .el-select-dropdown__item {
  height: 40px;
  line-height: 40px;
}
</style>
