<!--
 * @Author: zhangyutong
 * @Date: 2021-09-28 11:37:09
 * @LastEditTime: 2021-10-28 14:28:13
 * @LastEditors: zhangyutong
 * @FilePath: \lightning-web\src\views\quotationEmail\components\sendEmailDetail.vue
-->
<template>
  <el-dialog
    :title="$t('component.sendmail.label.title')"
    :visible="dialogVisibleEmail"
    v-if="dialogVisibleEmail"
    width="50%"
    class="dialog"
    :before-close="closeDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <send-email
      ref="sendemail"
      @closeEmailDialog="closeDialog(true)"
      @changeInvoiceStatus="changeInvoiceStatusl"
      :emailIsOperation="emailIsOperation"
      :enclosure="enclosure"
      :templateId="templateId"
      :recordId="recordId"
      :objId="objId"
      :invoiceReceiver="invoiceReceiver"
      :relevent="true"
      @changeButtonLoading="changeButtonLoading"
      @buttonLoadingChange="buttonLoadingChange"
    ></send-email>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">{{
        $t("label.emailobject.discard")
      }}</el-button>
      <el-button
        type="primary"
        @click="sendEmailMethod"
        :loading="buttonLoading"
        >{{ $t("label.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import sendEmail from "@/components/Email/sendEmail.vue";
import * as emailApi from "../api.js";

export default {
  props: {
    dialogVisibleEmail: {
      type: Boolean,
      default: false,
    },
    enclosurearr: {
      type: Array,
      default: () => [],
    },
    emailIsOperation: {
      type: Boolean,
      default: true,
    },
    templateId: {
      type: String,
      default: "",
    },
    recordId: {
      type: String,
      default: "",
    },
    objId: {
      type: String,
      default: "",
    },
    invoiceReceiver: {
      type: String,
      default: "",
    },
  },
  components: {
    sendEmail,
  },
  data() {
    return {
      enclosure: [],
      buttonLoading: false,
    };
  },
  watch: {
    enclosurearr(val) {
      this.enclosure = val;
    },
    dialogVisibleEmail(val) {
      if (val && this.$refs.sendEmail) {
        this.$nextTick(() => {
          this.$refs.sendEmail.getEmailObjectGetEmailPageInfos();
        });
      }
    },
  },
  methods: {
    //邮件发送成功更改发票状态
    async changeInvoiceStatus() {
     await emailApi.emailSuccess({ invoiceid: this.recordId });
    },
    //更改按钮状态
    changeButtonLoading() {
      this.buttonLoading = true;
    },
    buttonLoadingChange() {
      this.buttonLoading = false;
    },
    //发送邮件
    sendEmailMethod() {
      this.$refs.sendemail.sendEmail("1");
    },
    closeDialog(successFlg) {
      this.$emit("closeEmailDialog", successFlg);
    },
    changeInvoiceStatusl(status){
      if(status){
        this.changeInvoiceStatus()
      }
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-dialog__header .el-dialog__title {
  font-size: 18px;
}
::v-deep .el-dialog {
  height: 80%;
}
::v-deep .el-dialog__body {
  height: calc(100% - 120px);
  padding: 30px 40px;
}
::v-deep .el-dialog__footer {
  height: 63px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
</style>