<template>
  <div>
    <el-dialog
      title=""
      width="50%"
      :visible="isShowggRecord"
      :before-close="closeLib"
    >
      <!--      发布范围-->
      <div slot="title" class="dialog-title">
        <span style="font-weight: 800">{{
          $t("label.ggReleaseRange.release")
        }}</span>
      </div>
      <div class="management-content">
        <div class="management-content-top">
          <div class="sc-top-left">
            <!-- 添加 -->
            <div class="sc-top-left-tip">{{ $t("label.icon.22") }}</div>
            <div style="display: flex">
              <div class="select-role-type">
                <el-select
                  style="width: 60px"
                  v-model="checkedType"
                  @change="handleChangeType"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <svg
                      class="icon iconList"
                      aria-hidden="true"
                      style="height: 20px; width: 20px; display: inline-block"
                    >
                      <use :href="'#icon-' + item.src"></use>
                    </svg>
                    <span
                      style="
                        display: inline-block;
                        vertical-align: 3px;
                        margin-left: 10px;
                      "
                      >{{ item.label }}</span
                    >
                  </el-option>
                </el-select>
                <svg class="icon show-checked-type" aria-hidden="true">
                  <use
                    :href="
                      checkedType === 'User'
                        ? '#icon-' + typeOptions[0].src
                        : checkedType === 'Role'
                        ? '#icon-' + typeOptions[1].src
                        : '#icon-' + typeOptions[2].src
                    "
                  ></use>
                </svg>
              </div>
              <!--              搜索用户-->
              <el-select
                ref="selA"
                v-model="value"
                multiple
                filterable
                remote
                reserve-keyword
                :placeholder="setPlaceholder(this.checkedType)"
                :remote-method="remoteMethod"
                @change="handleChangeList"
                @focus="handleFocusList"
                popper-class="select-down"
                :popper-append-to-body="false"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sc-top-right">
            <!--            只读 读写-->
            <el-select
              v-model="selectValue"
              :placeholder="$t('label.select.please')"
            >
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="management-content-center">
          <!--          发布列表 -->
          <div class="sc-center-banner" @click="openOrClose">
            <span class="open-icon">
              <i :class="icons"></i>
            </span>
            {{ $t("label.ggReleaseRange.publish") }}
          </div>
          <div v-show="showMore">
            <div class="sc-center-list" v-loading="tableLoading">
              <div class="fb-title">
                <!-- 名称 -->
                <span>{{ $t("component.newrecords.label.name") }}</span>
                <!-- 类型 -->
                <span>{{ $t("label.emailtocloudcc.filed.type") }}</span>
                <!-- 原因 -->
                <span>{{ $t("label.reason") }}</span>
                <!-- 访问权限级别 -->
                <span>{{ $t("label.accesslimittype") }}</span>
              </div>
              <div class="sc-gd">
                <div
                  class="sc-item"
                  v-for="(item, index) in currentRoleList"
                  :key="index"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.type }}</span>
                  <span>{{ item.rowcause }}</span>
                  <span>
                    <!-- 只读 读写  可以编辑的-->
                    <!-- 所有人 -->
                    <el-select
                      @change="changeRolePem(item)"
                      v-if="item.rowcause !== $t('label.chatter.owner')"
                      class="sc-item-right-select"
                      size="mini"
                      v-model="item.accesslevel"
                    >
                      <el-option
                        v-for="itemB in selectOptions"
                        :key="itemB.value"
                        :label="itemB.label"
                        :value="itemB.value"
                      >
                      </el-option>
                    </el-select>
                    <!-- 为所有人  直接显示label  不可以编辑的 -->
                    <label
                      v-if="item.rowcause == $t('label.chatter.owner')"
                      style="padding-left: 6px"
                      >{{ item.accesslevel }}</label
                    >
                    <!-- 和只读 读写一起出现的叉号  可删除操作的-->
                    <i
                      class="el-icon-close"
                      @click="delSingleItem(item, index)"
                      v-if="
                        item.rowcause !== $t('label.chatter.owner') &&
                        !item.showFlag
                      "
                    ></i>
                    <i class="el-icon-loading" v-if="item.showFlag"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="closeLib">{{ $t("label.cancel") }}</el-button>
        <!--        保存-->
        <el-button
          type="primary"
          @click="comfirmAddRole"
          :loading="saveLoading"
          >{{ $t("label.chatter.save") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as releaseRangeApi from "./api";

export default {
  name: "ggReleaseRange",
  props: {
    ggRecordid: {
      type: String,
    },
    isShowggRecord: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [], //搜索用户全部数据
      value: [], //搜索用户选中的用户数据
      tableLoading: false, //发布列表loading
      saveLoading: false, //保存loading
      loading: false, //搜索用户loading
      icons: "el-icon-arrow-up",
      showMore: false, //发布列表的展开和隐藏
      selectOptions: [
        // { value: 'check', label: '只读' },
        // { value: 'cooperation', label: '读写' },
        { value: "check", label: this.$i18n.t("label.readonly") },
        { value: "cooperation", label: this.$i18n.t("label.readwrite") },
      ],
      typeOptions: [
        {
          value: "User",
          // 用户 label.user
          label: this.$i18n.t("label.user"),
          src: "guanzhuzhe",
        },
        {
          value: "Role",
          // 角色
          label: this.$i18n.t("label.tabpage.rolez"),
          src: "rolegg",
        },
        {
          value: "RoleAndSub",
          // 角色和下属
          label: this.$i18n.t("label.tabpage.Rolesz"),
          src: "groupgg",
        },
        {
          value: "area",
          // 区域
          label: this.$i18n.t("setup.layout.component.area"),
          src: "groupgg",
        },
        {
          value: "areaAndSub",
          // 区域及下级区域
          label: this.$i18n.t("label.areaAndSub"),
          src: "groupgg",
        },
        {
          value: "Group",
          // 公用小组
          label: this.$i18n.t("label.publicgroup"),
          src: "groupgg",
        },
        {
          value: "partnerUser",
          // 合作伙伴用户
          label: this.$i18n.t("label.partneruser"),
          src: "groupgg",
        },
        {
          value: "partnerRole",
          // 合作伙伴角色
          label: this.$i18n.t("label.roleofpartner"),
          src: "groupgg",
        },
      ],
      selectValue: "check", //只读 读写下拉框 默认 只读
      checkedType: "Role", //搜索用户下拉框  默认 角色
      currentRoleList: [], // 获取已发布列表的数据
      editChangesData: [], //编辑更改只读 读写的数据
      deleteEveryData: [], //删除发布列表的数据
      qzDeleteEveryData: [], //去重后的删除发布列表的数据
    };
  },
  mounted() {},
  created() {},
  methods: {
    // 动态改变搜索框的placeholder
    setPlaceholder(show) {
      if (show === "User") {
        return this.$i18n.t("label.projectManagement.search.for.users"); //搜索用户
      } else if (show === "Role") {
        return this.$i18n.t("label.ggReleaseRange.searchRole"); //搜索角色
      } else if (show === "RoleAndSub") {
        return this.$i18n.t("label.ggReleaseRange.subordinates"); //搜索角色和下属
      } else if (show === "Group") {
        return this.$i18n.t("label.ggReleaseRange.searchTeam"); //搜索公用小组
      } else if (show === "partnerUser") {
        return this.$i18n.t("label.ggReleaseRange.searchUsers"); //搜索合作伙伴用户
      } else if (show === "partnerRole") {
        return this.$i18n.t("label.ggReleaseRange.searchforRole"); //搜索合作伙伴角色
      } else if (show === "area") {
        return this.$i18n.t("setup.layout.component.area"); //区域
      } else if (show === "areaAndSub") {
        return this.$i18n.t("label.areaAndSub"); //区域及下级区域
      } else {
        return "";
      }
    },
    // 关闭弹框
    closeLib() {
      // 子传父  关闭弹框
      this.$emit("closeGgRecord", false);
      this.value = [];
      this.options = [];
      this.selectValue = "check";
      this.checkedType = "Role";
    },
    // 确认保存后 恢复初始默认设置
    savesomedata() {
      this.value = [];
      this.options = [];
      this.editChangesData = [];
      this.qzDeleteEveryData = [];
      this.selectValue = "check";
      this.checkedType = "Role";
    },
    handleChangeType() {
      this.options = [];
    },
    handleChangeList() {},

    // 收起关闭
    openOrClose() {
      // 切换箭头
      this.icons =
        this.icons === "el-icon-arrow-down"
          ? "el-icon-arrow-up"
          : "el-icon-arrow-down";
      // 控制发布列表的展开和关闭
      this.showMore = this.icons === "el-icon-arrow-down" ? true : false;
      // 获取已选发布列表
      this.getRoles();
    },

    // 获取焦点搜索用户请求列表
    handleFocusList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let option = {
          type: this.checkedType,
          searchKeyWord: this.searchQuery,
        };
        releaseRangeApi
          .searchGroup(option)
          .then((res) => {
            this.options = res.data;
          })
      }, 200);
    },

    // 搜索用户列表
    remoteMethod(query) {
      this.searchQuery = query;
      let option = {
        type: this.checkedType,
        searchKeyWord: this.searchQuery,
      };
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        releaseRangeApi
          .searchGroup(option)
          .then((res) => {
            this.options = res.data;
            this.searchQuery = "";
          })
      }, 200);
    },

    // 获取已选发布列表
    getRoles() {
      this.tableLoading = true;
      releaseRangeApi.getShareRuleList({ id: this.ggRecordid }).then((res) => {
        this.currentRoleList = res.data;
        this.currentRoleList.forEach((item) => {
          item["showFlag"] = false;
        });
        this.tableLoading = false;
      });
    },

    // 添加公告发布的人员
    addAnnouncement() {
      // 所选择的可以公告发布的人员数据
      let personnelData = this.value.toString(",");
      let op = {
        parentId: this.ggRecordid,
        selected: personnelData,
        accessLevel: this.selectValue === "check" ? "1" : "2",
      };
      releaseRangeApi.saveShareRule(op).then(() => {
        // 获取已选发布列表
        this.getRoles();
        this.saveLoading = false;
        // 添加成功
        this.$message.success(this.$i18n.t("vue_label_notice_add_success"));
      });
    },
    // 记录编辑改变只读 读写的所有数据
    changeRolePem(obj) {
      let ob = {
        id: obj.id,
        shareRoleType: obj.accesslevel === "check" ? "1" : "2",
      };
      this.editChangesData.push(ob);
    },

    // 调取保存辑改变只读读写的接口方法
    saveEditChangesMethod() {
      this.editChangesData.forEach((item) => {
        let op = {
          parentId: this.ggRecordid,
          shareRuleId: item.id,
          accessLevel: item.shareRoleType,
        };
        releaseRangeApi.saveShareRule(op).then(() => {
          // 获取已选发布列表
          this.getRoles();
          this.saveLoading = false;
          // 编辑更改成功
          this.$message.success(this.$i18n.t("vue_label_notice_add_success"));
        });
      });
    },
    // 含有对象的数组去重方法
    unique(arr) {
      const res = new Map();
      return arr.filter(
        (arr) => !res.has(arr.everyId) && res.set(arr.everyId, 1)
      );
    },
    // 点击删除发布列表成员的数据
    delSingleItem(obj, i) {
      this.$set(this.currentRoleList[i], "showFlag", true);
      this.$forceUpdate();
      let delData = {
        everyId: obj.id,
      };
      this.deleteEveryData.push(delData);
      // 去重后存储的删除人员id 数组
      this.qzDeleteEveryData = this.unique(this.deleteEveryData);
    },
    // 调取删除发布列表成员的接口方法
    deletePublishList() {
      this.qzDeleteEveryData.forEach((item) => {
        let res = {
          parentId: this.ggRecordid,
          shareRuleId: item.everyId,
        };
        releaseRangeApi.deleteShareRule(res).then(() => {
          // 获取已选发布列表
          this.getRoles();
          // 删除成功
          this.$message.success(this.$i18n.t("label.tabpage.delsuccessz"));
        });
      });
    },

    // 确认保存
    comfirmAddRole() {
      this.saveLoading = true;
      // 添加公告发布的人员列表
      this.addAnnouncement();
      // 调取保存辑改变只读读写的接口方法
      this.saveEditChangesMethod();
      // 删除发布列表单个成员
      this.deletePublishList();
      // 确认保存后 恢复初始默认设置
      this.savesomedata();
      // 子传父  关闭弹框
      this.$emit("closeGgRecord", false);
    },
  },
};
</script>

<style lang="scss" scoped>
// 下拉框去圆边角
::v-deep .el-input__inner {
  border-radius: 0 !important;
}

::v-deep .el-tag.el-tag--info {
  display: flex;
  align-items: center;
}
::v-deep .el-select__tags-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
::v-deep .select-down {
  min-width: 100px !important;
  max-width: 200px !important;
}
::v-deep .select-down .el-select-dropdown__item {
  width: 200px !important;
}
::v-deep .el-input__suffix-inner .el-icon- {
  transform: rotate(0deg);
}
::v-deep .el-icon-:before {
  content: "\e778";
}
::v-deep .el-dialog__body {
  padding: 16px 0px;
  overflow: visible;
}
::v-deep .el-select {
  width: 100%;
}
.management-content {
  width: 100%;
  padding: 0 30px;
  .management-content-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // padding: 0 36px;
    box-sizing: border-box;
    margin-bottom: 30px;
    .sc-top-left {
      width: 75%;
      .sc-top-left-tip {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
    .sc-top-right {
      width: 20%;
      // border: solid 1px;
      .el-select {
        margin-top: 31px;
        ::v-deep .el-input__inner {
          border-radius: 4px !important;
        }
      }
    }
  }
  .management-content-center {
    width: 100%;
    margin-bottom: 50px;
    .sc-center-banner {
      // border: solid 1px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      font-weight: 700;
      box-sizing: border-box;
      background: #f3f2f2;
      margin-bottom: 20px;
      cursor: pointer;
      .open-icon {
        display: inline-block;
        margin-right: 6px;
      }
    }
    .sc-center-list {
      border: 2px solid #dedcda;
      // padding: 15px;
      //   overflow-y: auto;
      .fb-title {
        span {
          display: inline-block;
          height: 45px;
          padding: 0 0 0 15px;
          line-height: 45px;
          border-bottom: 2px solid #dedcda;
          border-right: 1px solid #dedcda;
          font-size: 14px;
          font-weight: 700;
          background: #fcfcfc;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          box-sizing: border-box;
          &:nth-of-type(1) {
            width: 35%;
          }
          &:nth-of-type(2) {
            width: 20%;
          }
          &:nth-of-type(3) {
            width: 20%;
          }
          &:nth-of-type(4) {
            width: 25%;
            border-right: none;
          }
        }
      }
      .sc-gd {
        height: 120px;
        overflow-y: auto;
        .sc-item {
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #dedcda;
          span {
            display: inline-block;
            width: 25%;
            padding: 0 0 0 15px;
            white-space: nowrap;
            box-sizing: border-box;
            &:nth-of-type(1) {
              width: 35%;
            }
            &:nth-of-type(2) {
              width: 20%;
            }
            &:nth-of-type(3) {
              width: 20%;
            }
            &:nth-of-type(4) {
              width: 25%;
            }
            .sc-item-right-select {
              width: 90px;
              // width: 50%;
            }
            .el-icon-close {
              display: inline-block;
              cursor: pointer;
              font-size: 16px;
              margin-left: 10%;
            }
            .el-icon-loading {
              display: inline-block;
              margin-left: 10%;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.select-role-type {
  position: relative;
  ::v-deep .el-input__inner {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-right: none;
  }
  .iconList {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  .show-checked-type {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 23px;
    height: 23px;
  }
}
</style>
