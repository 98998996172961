<template>
  <!-- 开发人员：陈稣妹 -->
  <div class="assets-move">
    <!-- 迁移父资产弹框 -->
    <!-- 选择父资产 label.selecting.parent.assets -->
    <el-dialog
      :visible.sync="AssetsMoveDialogVisible"
      :title="$t('label.selecting.parent.assets')"
      custom-class="dialogWidth"
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <!-- 查找条件 -->
        <base-form
          ref="baseForm"
          :form-attr="formAttr"
          :label-width="labelWidth"
          v-if="formAttr && formAttr.length > 0"
        >
        </base-form>

        <!-- 无搜索条件时展示模糊搜索框 -->
        <el-input v-else v-model="searchKeyWord" class="searchKeyWord-input">
        </el-input>
        <!-- 搜索按钮 -->
        <el-form size="mini" :inline="true">
          <el-form-item>
            <el-button type="primary" @click="search">
              <!-- 搜索 -->
              {{ $t("label.quickbooks.search") }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="setCurrent()">
              <!-- 清空筛选 -->
              {{ $t("label.empty.filter") }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="$refs.createEditObj.add()">
              <!-- 新建资产 -->
              {{ $t("label.add.newAsset") }}
            </el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          height="300"
          :row-style="{ height: '30px' }"
          @current-change="handleCurrentChange"
          style="width: 100%; border: 1px solid #dedcda"
        >
          <el-table-column
            v-for="(item, index) in tableHeader"
            :key="index"
            :property="item.apiname"
            :show-overflow-tooltip="true"
            :label="item.label"
          >
            <template slot-scope="scope">
              <div v-if="item.schemefieldType === 'Y'">
                {{ scope.row[`${item.schemefieldName}ccname`] }}
              </div>
              <div v-else>
                {{ scope.row[`${item.schemefieldName}`] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="AssetsMoveDialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="saveAssetsMove"
          class="el-button--primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 产品转资产弹框 -->
    <!-- 订单产品转资产 label.order.products.to.assets -->
    <!-- 产品转资产BOM lable.Product.to.asset.BOM-->
    <el-dialog
      :visible.sync="AssetsProductDialogVisible"
      :title="$t('lable.Product.to.asset.BOM')"
      custom-class="dialogWidth"
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <p style="color: #080707">
          <!-- 确定将产品转资产? lable.products.to.assets-->
          {{ $t("lable.products.to.assets") }}
        </p>
        <p style="color: #888888">
          {{ $t("label.generated.transfer.product") }}
          <!-- 转资产时根据产品上“资产转换”的方式生成资产。 -->
        </p>
        <div class="Transferofassets">
          <el-table :data="ProductData" style="width: 100%" border>
            <!-- 产品名称 -->
            <el-table-column
              prop="name"
              :label="$t('label.product.name')"
              width="180"
            >
            </el-table-column>
            <!-- 产品选项 lable.Product.options-->
            <el-table-column
              prop="configuredskuccname"
              :label="$t('lable.Product.options')"
              width="180"
            >
            </el-table-column>
            <!-- 数量 label.file.num -->
            <el-table-column prop="quantity" :label="$t('label.file.num')">
            </el-table-column>
            <!-- 产品转换资产操作 lable.Product.conversion.asset.operation-->
            <el-table-column
              prop="assetconversion"
              :label="$t('lable.Product.conversion.asset.operation')"
            >
            </el-table-column>
            <el-table-column label="">
              <template slot-scope="scope">
                <span
                  class="delete-list"
                  @click="deleteListItem(scope.$index)"
                  v-if="ProductData.length > 0"
                >
                  <!-- 移除 -->
                  {{ $t("label.Remove") }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-checkbox v-model="productAssets" disabled>
          <!-- 根据产品结构生成资产BOM lable.asset.BOM.according.to.product-->
          {{ $t("lable.asset.BOM.according.to.product") }}
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="AssetsProductDialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button
          @click="productToBomFun"
          class="el-button--primary"
          size="mini"
          >{{ $t("label.confirm") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 订单产品转资产弹框 -->
    <!-- 订单产品 lable.Order.product-->
    <el-dialog
      :visible.sync="OrderProductDialogVisible"
      :title="$t('lable.Order.product')"
      width="50%"
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <p style="color: #080707">
          <!-- 确定将订单产品转资产？ -->
          {{ $t("label.confirm.order.productsToassets") }}
        </p>
        <!-- 转资产时根据订单产品上“资产转换”的方式生成资产。 lable.Asset.conversion-->
        <p style="color: #888888">
          {{ $t("lable.Asset.conversion") }}
        </p>
        <div class="Transferoforder">
          <table>
            <tr>
              <th
                v-for="HeaderField in OrderData.fieldMaplList"
                :key="HeaderField.id"
              >
                {{ HeaderField.labelName }}
              </th>
              <th></th>
            </tr>
            <tr
              v-for="(list, index) in OrderData.orderitemlist"
              :key="list.id"
              class="orderTr"
            >
              <td
                v-for="listItem in OrderData.fieldMaplList"
                :key="listItem.id"
              >
                {{
                  listItem.schemefieldType != "Y"
                    ? list[listItem.apiname]
                    : list[listItem.apiname + "ccname"]
                }}
              </td>
              <td
                style="white-space: nowrap; color: red; cursor: pointer"
                @click="deleteOrderList(index)"
              >
                <!-- 移除 -->
                {{ $t("label.Remove") }}
              </td>
            </tr>
          </table>
        </div>
        <el-checkbox v-model="productAssets" disabled>
          <!-- 根据订单的产品结构生成资产BOM -->
          {{ $t("label.asset.bom.based") }}
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="OrderProductDialogVisible = false" size="mini">
          {{ $t("label.cancel") }}</el-button
        >
        <el-button
          @click="orderItemToBOMFun"
          class="el-button--primary"
          size="mini"
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 更改所有人 -->
    <transfer-owner-dialog
      ref="transferAssetOwner"
      :id="id"
      :objId="objId"
      dialogTitle="转移资产BOM所有人"
      noticeInfo="转移资产BOM所有人时，会将当前资产和资产下的自己资产逐一更改所有人"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      :transfer-client-owner-dialog="AssetsTransferDialogVisible"
      @closeTransferOwnerDialog="AssetsTransferDialogVisible = false"
      @saveTransferOwner="saveTransferAssetBOMOwner"
    ></transfer-owner-dialog>
    <!-- 新建 编辑 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :object-api="objectApi"
      :object-name="objectName"
      :type="'active'"
      @save="save"
    ></create-edit-obj>
  </div>
</template>
<script>
import createEditObj from "./create-edit-obj.vue"; //新建 编辑
import BaseForm from "@/components/Form/base-form.vue";
import INPUTTYPE from "@/config/enumCode/searchTableInputType";
import * as CommonObjApi from "./api.js";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import TransferOwnerDialog from "@/components/TransferOwnerDialog/index.vue";

export default {
  name: "asset-move-owner",
  mixins: [TransferOwnerMixin],
  components: {
    createEditObj,
    BaseForm,
    TransferOwnerDialog,
  },
  props: {
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 对象Api（路由传递）
    objectApi: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    objectName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableHeader: [],
      tableData: [],
      currentRow: null,
      AssetsMoveDialogVisible: false, //迁移弹框
      AssetsProductDialogVisible: false, //产品转资产
      AssetsTransferDialogVisible: false, //转移
      OrderProductDialogVisible: false, //订单转资产弹窗
      // prefix: "s42", //接口参数
      fieldId: "ffe2014000032890C8YD",
      objId: "asset",
      productAssets: true, //确定产品转资产
      page: 1,
      pageSize: 20,
      totalPage: 0,
      searchConditions: "", // 搜索条件
      searchKeyWord: "", // 搜索条件
      formAttr: [], // 搜索条件
      labelWidth: 130,
      isNext: false,
      ProductData: [],
      OrderData: [],
    };
  },
  mounted() {},
  methods: {
    //产品转资产BOM
    async productToBomFun() {
      if (this.ProductData.length != 0) {
        let obj = {};
        let ids = [];
        obj.id = this.id;
        this.ProductData.forEach((item) => {
          if (item.optionalsku) {
            ids.push(item.optionalsku);
          }
          if (item.id) {
            ids.push(item.id);
          }
        });
        obj.optionalskus = ids.join(",");
        let resultData = await CommonObjApi.productToBom(obj);
        if (resultData.result) {
          //产品转资产成功 lable.asset.successfully
          this.$message({
            message: this.$i18n.t("lable.asset.successfully"),
            type: "success",
          });
          this.AssetsProductDialogVisible = false;
        }
      } else {
        //没有可以转换为资产的产品   lable.no.products.to.assets
        this.AssetsProductDialogVisible = false;
        this.$message({
          message: this.$i18n.t("lable.no.products.to.assets"),
          type: "warning",
        });
      }
    },
    //订单产品转资产BOM
    async orderItemToBOMFun() {
      if (this.OrderData.orderitemlist.length != 0) {
        let obj = {};
        let ids = [];
        this.OrderData.orderitemlist.forEach((item) => {
          ids.push(item.id);
        });
        obj.ids = ids.join(",");
        let resultData = await CommonObjApi.orderItemToBOM(obj);
        if (resultData.result) {
          //订单产品转资产成功 lable.Order.successfully
          this.$message({
            message: this.$i18n.t("lable.Order.successfully"),
            type: "success",
          });
          this.OrderProductDialogVisible = false;
        }
      } else {
        //没有可以转换为资产的产品   lable.no.products.to.assets
        this.AssetsProductDialogVisible = false;
        this.$message({
          message: this.$i18n.t("lable.no.products.to.assets"),
          type: "warning",
        });
      }
    },
    //移除产品列表某项
    //移除成功 lable.Removal.succeeded
    deleteListItem(index) {
      this.ProductData.splice(index, 1);
      this.$message({
        message: this.$i18n.t("lable.Removal.succeeded"),
        type: "success",
      });
    },
    //移除订单产品列表某项
    deleteOrderList(index) {
      this.OrderData.orderitemlist.splice(index, 1);
      this.$message({
        message: this.$i18n.t("lable.Removal.succeeded"),
        type: "success",
      });
    },
    //获取订单产品列表
    async getOrderList(layoutid) {
      let resultData = await CommonObjApi.getOrderItem({
        id: this.id,
        layoutid: layoutid,
      });
      this.OrderData = resultData.data;
    },
    //获取产品选项列表
    async getProductandList() {
      let resultData = await CommonObjApi.getProductandPoption({
        id: this.id,
      });
      this.ProductData = resultData.data.cloudccoption;
      if (resultData.data.product) {
        this.ProductData.unshift(resultData.data.product);
      }
    },
    // 监听表格滚动
    // scrollTable() {
    //   this.$refs.singleTable.bodyWrapper.addEventListener(
    //     "scroll",
    //     (res) => {
    //       let height = res.target;
    //       let clientHeight = height.clientHeight;
    //       let scrollTop = height.scrollTop;
    //       let scrollHeight = height.scrollHeight;
    //       // 滚动条总高度 减去 窗口高度 减去 已卷入的高度 小于 10px 就加载新数据
    //       if (scrollHeight - clientHeight - scrollTop < 10) {
    //         if (this.page < this.totalPage && this.isNext) {
    //           this.isNext = false;
    //           this.page++;
    //           this.getTable();
    //         }
    //       }
    //     },
    //     true
    //   );
    // },
    // 新建资产后刷新数据
    save() {
      this.getTable();
    },
    // 请求资产搜索字段
    assetSearch() {
      this.page = 1;
      this.searchKeyWord = "";
      this.isNext = false;
      CommonObjApi.getLookupInfo({
        fieldId: this.fieldId,
        objId: this.objId,
        prefix: this.prefix,
      }).then((res) => {
        if (res.result) {
          if (res.data.Fields) {
            this.getFormAttr(res.data.Fields);
          } else {
            this.formAttr = [];
          }
          this.getTable();
        }
      });
    },
    // 获取父资产表格数据
    getTable() {
      this.isNext = false;
      CommonObjApi.findAssetList({
        assetId: this.id,
        page: this.page,
        pageSize: this.pageSize,
        searchConditions: this.searchConditions || "",
        searchKeyWord: this.searchKeyWord || "",
      }).then((res) => {
        if (res.result) {
          if (this.page === 1) {
            this.tableHeader = res.data.headField;
            this.tableData = res.data.tableData;
            this.totalPage = res.data.totalPage;
          } else if (this.page > 1) {
            this.tableData = [...this.tableData, ...res.data.tableData];
          }
          // 更新dom
          this.$nextTick(() => {
            this.isNext = true;
          });
        }
      });
    },
    // 获取表格搜索字段
    getFormAttr(attrs) {
      this.formAttr = [];
      attrs.forEach((item) => {
        item.prop = item.id;
        this.$set(item, "width", "50%");
        this.$set(item, "readonly", false);
        if (item.type === "Y" || item.type === "MR") {
          this.$set(item, "type", "input");
        } else {
          this.$set(item, "type", INPUTTYPE[item.type]);
        }
        // 百分数字段字数长度限制
        if (item.type === "number") {
          // item.precision = Number(item.decimalPlaces);
          item.valueInterval = {
            min: 0 - Math.pow(10, 18) + 1,
            max: Math.pow(10, 18) - 1,
          };
        }
        if (item.type === "select" || item.type === "multi-select") {
          item.options = [];
          item.selectOptions &&
            item.selectOptions.forEach((select) => {
              item.options.push({
                key: select.value,
                val: select.value,
              });
            });
        }
        // 如果是报价单明细，特殊处理
        if (item.name === "bjdmc") {
          item.type = "input";
        }
        this.formAttr.push(item);
      });
    },
    // 搜索
    search() {
      this.page = 1;
      let obj = {};
      this.formAttr &&
        this.formAttr.length > 0 &&
        this.formAttr.forEach((item) => {
          obj[item.prop] = this.$refs.baseForm.formData[item.prop];
        });
      this.searchConditions = JSON.stringify(obj);
      this.getTable();
    },
    // 清空筛选
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
      this.searchConditions = "";
      this.searchKeyWord = "";
      this.isNext = false;
      this.assetSearch();
    },
    // 选中值更改
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    // 迁移资产
    // assetsMove() {
    //   this.AssetsMoveDialogVisible = !this.AssetsMoveDialogVisible;
    //   // 更新dom
    //   //ref在这段方法中可以调到
    //   this.$nextTick(() => {
    //     this.assetSearch();
    //     //此时能够调到ref，而且table也正常刷新了
    //     this.scrollTable();
    //   });
    // },
    // 确定转移资产
    saveAssetsMove() {
      if (this.currentRow) {
        CommonObjApi.transferAssetBOM({
          // assetId	是	String	资产id
          // newParentAssetId	是	String	新的父级资产ID
          assetId: this.id,
          newParentAssetId: this.currentRow.id,
        }).then((res) => {
          if (res.result) {
            this.$emit("updateDetail");
            this.AssetsMoveDialogVisible = false;
          }
        });
      } else {
        //请选择一个资产 this.$i18n.t("label.select.an.asset")
        this.$message.warning(this.$i18n.t("label.select.an.asset"));
      }
    },
    // 产品转资产
    async assetsProduct() {
      let resultData = await CommonObjApi.productToBomPerm({});
      if (JSON.parse(resultData.data)) {
        this.getProductandList();
        this.AssetsProductDialogVisible = !this.AssetsProductDialogVisible;
      } else {
        //抱歉，您无权进行此操作，请联系系统管理员 lable.system.administrator
        this.$message({
          message: this.$i18n.t("lable.system.administrator"),
          type: "error",
        });
      }
    },
    //订单产品转资产
    // async orderProduct(params, layoutId) {
    //   let resultData = await CommonObjApi.productToBomPerm({ id: this.id });
    //   if (JSON.parse(resultData.data.perm)) {
    //     //订单未启用，请先启用订单 lable.Please.enable.order
    //     if (!JSON.parse(resultData.data.activated)) {
    //       this.$message({
    //         message: this.$i18n.t("lable.Please.enable.order"),
    //         type: "error",
    //       });
    //     } else {
    //       this.getOrderList(layoutId);
    //       this.OrderProductDialogVisible = !this.OrderProductDialogVisible;
    //     }
    //   } else {
    //     //抱歉，您无权进行此操作，请联系系统管理员 lable.system.administrator
    //     this.$message({
    //       message: this.$i18n.t("lable.system.administrator"),
    //       type: "error",
    //     });
    //   }
    // },
    // 转移资产
    // assetsTransfer() {
    //   this.ownerName = "";
    //   this.ownerId = "";
    //   this.isSendMail = false;
    //   this.initTransferOwner(this.objId, this.id);
    //   this.AssetsTransferDialogVisible = true;
    // },
    // 保存转移资产BOM所有人
    saveTransferAssetBOMOwner(email, id, ownerId) {
      let config = {
        assetId: id,
        newOwner: ownerId,
        isSendEmail: email,
      };
      CommonObjApi.transferAssetBOMOwner(config).then((res) => {
        if (res.result) {
          this.$emit("updateDetail");
          this.AssetsTransferDialogVisible = false;
        }
      });
    },
    // 复制、删除资产BOM
    // handleAsset(flag) {
    //   let config = {
    //     assetId: this.id,
    //   };
    //   switch (flag) {
    //     case "del":
    //       CommonObjApi.deleteAssetBOM(config).then((res) => {
    //         if (res.result) {
    //           // 删除资产BOM成功 this.$i18n.t("label.asset.deleted.successfully")
    //           this.$message.success(
    //             this.$i18n.t("label.asset.deleted.successfully")
    //           );
    //           this.$router.go(-1);
    //         }
    //       });
    //       break;
    //     case "copy":
    //       CommonObjApi.copyAssetBOM(config).then((res) => {
    //         if (res.result) {
    //           // 复制资产BOM成功 this.$i18n.t("label.copy.asset.successfully")
    //           this.$message.success(
    //             this.$i18n.t("label.copy.asset.successfully")
    //           );
    //         }
    //       });
    //       break;
    //   }
    // },
  },
};
</script>
<style scoped lang="scss">
::v-deep .dialogWidth {
  width: 50% !important;
}
.Transferofassets {
  ::v-deep .el-table__header tr th {
    border-right: none;
  }
  ::v-deep .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .delete-list {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.Transferoforder {
  padding: 20px 0;
  table {
    th {
      padding: 10px;
      text-align: center;
      white-space: nowrap;
      border-top: 1px solid #dedcda;
      border-bottom: 1px solid #dedcda;
      background-color: #fafaf9;
    }
    th:first-child {
      border-left: 1px solid #dedcda;
    }
    th:last-child {
      border-right: 1px solid #dedcda;
    }
    .orderTr {
      td {
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #dedcda;
      }
      td:first-child {
        border-left: 1px solid #dedcda;
      }
      td:last-child {
        border-right: 1px solid #dedcda;
      }
    }
  }
}
.searchKeyWord-input {
  width: 50%;
  margin-bottom: 10px;
}
.el-checkbox {
  padding: 5px 0;
}
::v-deep .el-table__header tr th {
  background: #fafaf9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
  border-bottom: 1px solid #dedcda;
  border-right: 1px solid #dedcda;
}
::v-deep .el-input__inner {
  height: 34px;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
::v-deep .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
::v-deep .el-table tr {
  cursor: pointer;
}
::v-deep .el-table__body tr.current-row > td {
  background-color: #005fb2;
  color: #fff;
}
</style>
