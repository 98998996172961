<!--  -->
<template>
  <div class="selectqa">
    <el-dialog
      :title="$t('c113', { name: title })"
      :visible="dialogTableVisible"
      width="45%"
      :before-close="close"
    >
      <div class="search">
        <p>{{ $t("label.import.index.filename") }}</p>
        <el-input
          v-model="inputval"
          :placeholder="$t('label.tabpage.entercontent')"
        ></el-input>
      </div>
      <div class="buts">
        <el-button type="primary" size="mini" @click="search">{{
          $t("label.searchs")
        }}</el-button>
        <el-button type="primary" size="mini" @click="clearSelect">
          {{ $t("label_emailtocloudcc_clear") }}</el-button
        >
      </div>
      <!-- 列表 -->
      <table-panel
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-lock="false"
        :table-height="tableHeight"
        :show-index="false"
        :checked="checked"
        :pict-loading="pictLoading"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :use-hyperlinks="false"
        @append="append"
        @onchange="select"
      >
      </table-panel>

      <!-- 底部 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="sendEmail">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TablePanel } from "@/components/index";
import * as quotationEmail from "../api";
export default {
  components: { TablePanel },
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputval: "", //搜索文件
      tableHeight: "400px", //列表高度
      checked: true, //支持多选
      pictLoading: false,
      noDataTip: false,
      pageObj: {
        dataList: [],
      },
      List: [
        // {
        //   subject: '斗罗',
        //   creatdate: '2021-09-01'
        // },
      ],
      //表头
      tableAttr: [
        {
          colwidth: "",
          schemetableName: this.$i18n.t("label.quote.title"),
          schemefieldType: "S",
          schemefieldName: "name",
          apiname: "name",
          nameLabel: this.$i18n.t("label.quote.title"),
          click: "selectData",
        },
        {
          colwidth: "",
          schemetableName: this.$i18n.t("label.trigger.createdate"),
          schemefieldType: "S",
          schemefieldName: "createdate",
          apiname: "createdate",
          nameLabel: this.$i18n.t("label.trigger.createdate"),
        },
      ],
      selectfiles: [],
    };
  },
  methods: {
    init() {
      this.inputval = "";
      this.pageObj.dataList = [...this.List];
      this.selectfiles = [];
    },
    append() {},
    //获取列表数据
    getFilesdata() {
      let parms = {
        recordId: this.id,
      };
      quotationEmail.getFiles(parms).then((res) => {
        if (res.result) {
          this.List = res.data;
          if (res.data.length > 0) {
            this.$emit("closedselectquotation", true);
          } else {
            // this.$message({
            //   message: this.$i18n.t('c114',{name:this.title}),
            //   type: 'warning'
            // });
            this.$emit("opencreatedquo");
          }
          this.init();
        }
      });
    },
    //选择行
    select(arr) {
      this.selectfiles = arr;
    },
    //搜索
    search() {
      let obj = [];
      for (let i = 0; i < this.List.length; i++) {
        if (this.List[i].name.indexOf(this.inputval) !== -1) {
          obj.push(this.List[i]);
        }
      }
      this.pageObj.dataList = obj;
    },
    //清空
    clearSelect() {
      this.init();
    },
    //文件选择页面关闭
    close() {
      this.$emit("closedselectquotation", false);
    },
    sendEmail() {
      if (this.selectfiles.length > 0) {
        this.selectfiles.map((res) => {
          res.fileContentId = res.fileid;
          return res;
        });
        this.$emit("closedselectquotation", false, this.selectfiles);
      }
    },
    keydownFn() {
      var _this = this;
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search();
      }
    }
  },
  created() {
    document.addEventListener('keydown', this.keydownFn)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydownFn)
  },
};
</script>

<style lang='scss' scoped >
::v-deep .el-dialog {
  height: 742px;
}
::v-deep .el-dialog__body {
  height: 619px;
}
.search {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 20px;
  p {
    // width: 14%;
    font-size: 14px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #080707;
  }
  ::v-deep .el-input {
    width: 89%;
  }
}
.buts {
  margin-bottom: 20px;
  .el-button {
    // padding: 11.5px 15px;
    font-size: 14px;
  }
}
.dialog-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: right;
  .el-button:nth-child(2) {
    margin-right: 36px;
  }
}
</style>
