<!--
 * @Author: zhangyutong
 * @Date: 2021-09-13 17:02:00
 * @LastEditTime: 2021-11-05 17:27:50
 * @LastEditors: zhangyutong
 * @FilePath: \lightning-web\src\views\quotationEmail\components\createdQuotationDialog.vue
-->
<!--  -->
<template>
  <div class="">
    <!-- 报价单预览页面 -->
    <el-dialog
      :title="title"
      ref="priceItem"
      :visible="quotationDialogVisible"
      width="75%"
      top="10px"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :lock-scroll="true"
    >
      <div class="mainContent">
        <div class="headContent">
          <el-select v-model="templateId" size="mini" @change="getTemplate">
            <el-option
              v-for="item in templateList"
              :key="item.templateid"
              :label="item.templatename"
              :value="item.templateid"
              :disabled="item.isenable !== '1'"
            ></el-option>
          </el-select>
          <el-dropdown @command="handleCommand" v-if="type == 'quotation'">
            <el-button size="mini">
              <i class="el-icon-more-outline"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="save">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-icon-save"></use>
                </svg>
                <span class="ml-10">{{
                  $t("component.telerecord.button.save")
                }}</span
                ><span class="ml-50">Ctrl + S</span></el-dropdown-item
              >
              <el-dropdown-item command="print">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-icon-print"></use>
                </svg>
                <span class="ml-10">{{ $t("label.tabpage.printz") }}</span
                ><span class="ml-50">Ctrl + P</span>
              </el-dropdown-item>
              <el-dropdown-item command="send">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-icon-send"></use>
                </svg>
                <span class="ml-10">{{
                  $t("component.sendmail.button.send")
                }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="inner" :style="{ height: heightDialog }">
          <div class="mainContainer" v-loading="showPrint">
            <embed
              v-show="!showPrint"
              :src="'data:application/pdf;base64,' + templatePdf + '#toolbar=0'"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button
          size="mini"
          type="primary"
          class="blueBt"
          @click="sendEmail('send')"
          :loading="buttonLoading"
        >
          {{ $t("label.file.save.to.send") }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          class="blueBt"
          @click="sendEmail"
          :loading="buttonLoading"
          v-if="type == 'quotation'"
        >
          {{ $t("vue_label_file_save_to_file") }}
        </el-button>
        <el-button size="mini" class="whiteBt" @click="handleClose">
          {{ $t("button_partner_cancel") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import * as quotationEmail from "../api";

export default {
  props: {
    quotationDialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    objId: {
      type: String,
      default: "",
    },
    templateList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "quotation",
    },
    title: {
      type: String,
      default: "报价单预览",
    },
  },
  data() {
    return {
      //按钮loading
      buttonLoading: false,
      //模板展示loading
      showPrint: false,
      //活动模板文档
      embedLoading: false,
      // 文件名称
      printTitle: "",
      // 模板id
      templateId: "",
      // 模板tpye
      templateType: "",
      templateData: {},
      // base64 图片文件
      templatePdf: "",
      heightDialog: `${document.body.offsetHeight - 250}px`,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.saveContent);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.saveContent);
  },
  //方法集合
  methods: {
    init(templateData) {
      this.printTitle = templateData.templateFile;
      this.templateId = templateData.templateId;
      this.getTemplate();
    },
    //获取模板详情
    getTemplate() {
      this.showPrint = true;
      this.templateList.forEach((item) => {
        if (item.templateid === this.templateId) {
          this.templateType = item.type;
        }
      });
      quotationEmail
        .getTemplatePdf({
          id: this.templateId,
          objid: this.objId,
          name: this.printTitle,
          type: this.templateType,
          recordId: this.id,
        })
        .then((res) => {
          if (res.result) {
            this.showPrint = false;
            this.templatePdf = res.data.templatePdf;
          } else {
            this.showPrint = false;
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    saveContent(e) {
      var key = window.event.keyCode
        ? window.event.keyCode
        : window.event.which;
      if (key === 83 && e.ctrlKey) {
        // ctrl+S 处理事件
        e.preventDefault();
      } else if (key === 80 && e.ctrlKey) {
        this.printpage();
        e.preventDefault();
      }
    },
    handleCommand(command) {
      if (command === "print") {
        this.printpage();
      } else if (command === "save") {
        this.sendEmail();
      } else if (command === "send") {
        this.sendEmail("send");
      }
    },
    printpage() {
      window.open(
        `#/print-quotation/${this.printTitle}/${this.objId}/${this.id}/${this.templateId}/${this.templateType}`,
        "_parent - URL",
        "height=700, width=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    },
    handleClose() {
      this.$emit("closeQuotaionDialog", false);
    },
    // 保存文件
    sendEmail(send) {
      this.buttonLoading = true;
      let params = {
        id: this.templateId,
        recordId: this.id,
        objid: this.objId,
        fileName: this.printTitle,
        type: this.templateType,
      };
      quotationEmail.saveTemplatePdf(params).then((res) => {
        if (res.result) {
          this.buttonLoading = false;
          let obj = {
            fileContentId: res.data.fileId,
            name: res.data.fileFileName,
          };
          if (send == "send") {
            this.$emit("closeQuotaionDialog", true, [obj]);
          } else {
            this.$message.success(this.$i18n.t("label.search.saveok"));
            this.$emit("closeQuotaionDialog", true);
          }
        } else {
          this.buttonLoading = false;
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@media print {
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  button {
    display: none;
  }
  table tr td:last-child {
    padding-right: 10px !important;
  }
  .print_btn {
    display: none;
  }
}
.ml-50 {
  margin-left: 50px;
}
.blueBt {
  background: #005fb2;
  border-radius: 3px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
}
.whiteBt {
  border-radius: 3px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  border: 1px solid #dcdcdc;
}
.mainContent {
  .headContent {
    height: 55px;
    background: #f7f7f7;
    border: 1px solid #dddbda;
    padding: 12px 36px;
    display: flex;
    justify-content: space-between;
  }
  .inner {
    background-color: #f0f0f0;
    padding: 0px 40px;
    overflow: auto;
    margin-bottom: 20px;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #dcdcdc;
    .mainContainer {
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      height: 100%;
      .main_title {
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        text-align: center;
      }
    }
  }

  ::v-deep .el-dialog__body {
    padding: 16px 36px;
    font-size: 12px !important;
    word-break: normal !important;
  }
}
</style>