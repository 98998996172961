<!--
 * @Author: 贾洁飞
 * @FilePath: \lightning-web\src\components\ButtonWrapper\seasPool.vue
 公海池弹框封装
-->
<template>
  <div class="seasPool">
    <el-dialog
      :visible="seaShow"
      :title="
        $t('vue_label_pool_assign1', [
          clientPoolOprationObject.oprationDirctiveName,
          clientPoolCheckDialogTitle,
        ])
      "
      top="15%"
      width="25%"
      :close-on-click-modal="true"
      :before-close="handleClose"
      @closed="clientPoolClosed"
    >
      <div v-loading="Back_Discard_FormOptionListLoading">
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <el-form :model="Back_Discard_Form" ref="ruleForm">
            <span style="color: red">*</span>
            {{ $t("vue_label_pool_selecttransferinto") }}

            <br />
            <el-select
              v-model="Back_Discard_Form.backtopoolId"
              prop="backtopoolId"
              :rules="{
                required: true,
                message: 'xxxxxxxxxxxx',
                trigger: ['change', 'blur'],
              }"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
              style="width: 100%"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in seaoption"
                :key="index"
              />
            </el-select>
          </el-form>
        </div>

        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!--原因 -->
          <span style="color: red">*</span>
          {{
            $t("vue_label_pool_reason", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-select
          v-model="Back_Discard_Form.firstSelect"
          prop="firstSelect"
          :rules="{
            required: true,
            message: 'xxxxxxxxxxxx',
            trigger: ['change', 'blur'],
          }"
          style="width: 100%"
        >
          <el-option
            :label="item.codevalue"
            :value="item.codekey"
            v-for="(item, index) in Back_Discard_FormOptionList"
            :key="index"
          />
        </el-select>
        <div class="clientPoolDialogIsShow_Back_Discard-margin">
          <!-- {{ clientPoolOprationObject.oprationDirctiveName }}原因描述 -->
          {{
            $t("vue_label_pool_reasondescription", [
              clientPoolOprationObject.oprationDirctiveName,
            ])
          }}
        </div>
        <el-input
          type="textarea"
          v-model="Back_Discard_Form.secondDesc"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clientPoolCancle" size="mini">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button
          @click="marketseaReturnAndRecovery()"
          type="primary"
          size="mini"
          :loading="Back_Discard_FormOptionListLoading"
        >
          <!-- 确定 -->
          {{ $t("label.ems.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getReturnSeasPool,
  queryMarketSeaList,
  getSelectValue,
} from "./api.js";

export default {
  name: "seasPool",
  props: {
    seaShow: {
      type: Boolean,
    },
    objid: {
      type: String,
    },
    objectApi: {
      type: String,
    },
  },
  data() {
    return {
      seaoption: [],
      // clientPoolDialogIsShow_Back_Discard: false, //公海池右边操作按钮对话框【退回，废弃】
      // 退回、废弃表单(选中的参数)
      Back_Discard_Form: {
        firstSelect: "",
        secondDesc: "",
        backtopoolId: "", //退回公海池id
      },
      Back_Discard_FormOptionList: [],
      Back_Discard_FormOptionListLoading: false, //退回、废弃的下拉选项列表请求接口时loading
      // 操作指令对象
      clientPoolOprationObject: {
        oprationDirctive: "", //操作指令,当前对话框所附带的事件类型（每一个 action 按钮的事件名称 ）
        oprationDirctiveName: "", //操作的指令名称
        // tableCheckedObjectArr
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {
    clientPoolCheckDialogTitle() {
      if (this.objid === "lead") {
        //  如果是潜在客户
        return this.$i18n.t("vue_label_normal_lead_sf");
      } else if (this.objid === "account") {
        //  如果是客户
        return this.$i18n.t("vue_label_normal_account_sf");
      } else {
        return ""
      }
    },
  },
  // 监控data中的数据变化
  watch: {
    seaShow() {
      if (this.seaShow) {
        this.selMarketsea();
        this.Back_Discard_FormOptionListFun("Back");
      }
    },
  },
  // 方法集合
  methods: {
    //退回公海池
    async marketseaReturnAndRecovery() {
      // 回退公海池进行公海池和原因的非空校验
      if(!this.Back_Discard_Form.backtopoolId){
        // 请选择公海池
        this.$message.error(this.$i18n.t('c995'));
        return ;
      }
      if(!this.Back_Discard_Form.firstSelect){
        // 请选择原因！
        this.$message.error(this.$i18n.t('c996'));
        return ;
      }
      let id = "";
      if (this.selectedList !== "[]") {
        id = sessionStorage.getItem("id");
      } else {
        id = this.selectedList.map((item) => item.id).join(",");
      }
      let params = {
        marketseaid: this.Back_Discard_Form.backtopoolId,
        objectApi: this.objectApi,
        thyyms: this.Back_Discard_Form.secondDesc,
        id,
        type: "return",
        rejectreason: this.Back_Discard_Form.firstSelect,
      };
      let res = await getReturnSeasPool(params);

      if (res.result == true) {
        this.$message({
          message: this.$i18n.t("lable.Returned.to.successful"),
          type: "success",
        });
        this.$router.go(-1);
      }
      this.clientPoolCancle();
    },
    //获取对象下公海池
    async selMarketsea() {
      let params = {
        id: this.objid,
      };
      let res = await queryMarketSeaList(params);
      this.seaoption = res.data;
    },
    handleClose(done) {
      // 更新seaShow值
      this.$emit('update:seaShow',false)
      done();
    },
    // 公海池的对话框动画执行完毕，自动清除事件指令【交互优化】
    clientPoolClosed() {
      // 清除公海池的弹窗保留的事件名称
      this.clientPoolOprationObject = {};
      this.clientPoolCancle();
    },
    // 废弃、退回 下拉操作列表获取
    Back_Discard_FormOptionListFun(status) {
      /**
          ffeleadmarketsea05  潜在客户 退回原因
          ffeaccountmarketsea5  客户
          ffeleadmarketsea07 潜在 客户废弃
          ffeaccountmarketsea7 客户废弃
         */
      let fieldId = null;
      if (this.objid === "lead") {
        //  如果是潜在客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeleadmarketsea05";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeleadmarketsea07";
        }
      } else if (this.objid === "account") {
        //  如果是客户
        if (status === "Back") {
          // 如果是 【退回操作】
          fieldId = "ffeaccountmarketsea5";
        } else if (status === "Discard") {
          // 如果是 【废弃操作】
          fieldId = "ffeaccountmarketsea7";
        }
      }
      // 加载中
      this.Back_Discard_FormOptionListLoading = true;
      // 获取选项下拉列表
      getSelectValue({
        fieldId,
      }).then((res) => {
        res.result && (this.Back_Discard_FormOptionList = res.data);
        // 加载中
        this.Back_Discard_FormOptionListLoading = false;
      });
    },
    clientPoolCancle() {
      this.$emit("clientPoolCancle");
    },
  },
};
</script>
<style lang='scss' scoped>
/* @import url(); 引入公共css类 */
.clientPoolDialogIsShow_Back_Discard-margin {
  margin: 5px 0;
}
</style>
